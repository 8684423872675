import React from "react";

const Footer1 = () => {
  return (
    <div id="footer1">
      <div className="flex">
        <div className="copyright">
          &copy; Copyright{" "}
          <strong>
            <span>Easy Properti</span>
          </strong>
          . All Rights Reserved
        </div>
        <div className="credits">
          Designed by <a href="https://abhilaksharora.tech/">Abhilaksh Arora</a>
        </div>
      </div>
    </div>
  );
};

export default Footer1;
