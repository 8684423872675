import React, { useEffect } from "react";
import Topbar from "../components/Topbar";
import Header from "../components/Header";
import Hero1 from "../components/Hero1";
import About from "../components/About";
import Why from "../components/Why";
import Clients from "../components/Clients";
import Services from "../components/Services";
import Cta from "../components/Cta";
import Contact from "../components/Contact";
import Footer from "../components/Footer";
import { useRef } from "react";
import Abroad from "../components/Abroad";
import Property from "../components/Property";
import ReactGA from "react-ga";

const Home = () => {
  const aboutRef = useRef(null);
  const mainRef = useRef(null);
  const serviceRef = useRef(null);
  const contactRef = useRef(null);
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);
  });
  return (
    <div>
      {/* <Topbar /> */}
      <Hero1
        aboutRef={aboutRef}
        mainRef={mainRef}
        serviceRef={serviceRef}
        contactRef={contactRef}
      />
      <Property />
      <Abroad />
      <Why />
      <About aboutRef={aboutRef} />
      <Clients />
      <Services serviceRef={serviceRef} />
      <Cta />
      <Contact contactRef={contactRef} />
      <Footer
        aboutRef={aboutRef}
        mainRef={mainRef}
        serviceRef={serviceRef}
        contactRef={contactRef}
      />
    </div>
  );
};

export default Home;
