import React, { useEffect, useState } from "react";
import Header1 from "../components/Header1";
import Footer from "../components/Footer";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PulseLoader from "react-spinners/PulseLoader";
import base from "../utils/base";
import Footer1 from "../components/Footer1";
import ReactGA from "react-ga";

const Sell = () => {
 
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState(null);

  const handleFileChange = (e) => {
    setSelectedFiles(e.target.files);
  };

  const spinner = (
    <PulseLoader
      color="white"
      loading={isLoading}
      size={8}
      aria-label="Loading Spinner"
      data-testid="loader"
    />
  );

  const [disputedProperty, setDisputedProperty] = useState("");
  const handleDisputedPropertyChange = (e) => {
    setDisputedProperty(e.target.value);
  };

  const [isLoan, setIsLoan] = useState("");
  const handleLoanChange = (e) => {
    setIsLoan(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    const formData = new FormData();

    formData.append("name", e.target.name.value);
    formData.append("email", e.target.email.value);
    formData.append("mob", e.target.mob.value);
    formData.append("whatsapp", e.target.whatsapp.value);
    formData.append("approximateCost", e.target.approximateCost.value);
    formData.append("timeToSell", e.target.timeToSell.value);
    // Add location data
    formData.append("lState", e.target.state.value);
    formData.append("lDistrict", e.target.district.value);
    formData.append("lCity", e.target.city.value);
    formData.append("lTehsil", e.target.tehsil.value);
    formData.append("lVillage", e.target.village.value);
    formData.append("lLandmark", e.target.landmark.value);
    formData.append("lPincode", e.target.pincode.value);

    // Add area of property data
    formData.append("aLength", e.target.length.value);
    formData.append("aWidth", e.target.width.value);
    formData.append("aTotalAreaSqFt", e.target.totalAreaSqFt.value);

    // Add property holder data
    formData.append("pName", e.target.pname.value);
    formData.append("pOwnershipType]", e.target.ownershipType.value);

    // Add loan data if applicable
    if (e.target.isLoan.value === "Yes") {
      formData.append("isLoan", e.target.isLoan.value);
      formData.append("amount", e.target.amount.value);
      formData.append("timePeriod", e.target.timePeriod.value);
      formData.append("bank", e.target.bank.value);
      formData.append("bankAddress", e.target.bankAddress.value);
    }

    formData.append("disputedProperty", e.target.disputedProperty.value);
    if (e.target.disputedProperty.value === "Yes") {
      formData.append(
        "disputedPropertyDetails",
        e.target.disputedPropertyDetails.value
      );
    }

    if(selectedFiles != null){

      for (let i = 0; i < selectedFiles.length; i++) {
        formData.append("files", selectedFiles[i]);
      }
    }

    try {
      const response = await fetch(`${base}/api/v1/add`, {
        method: "POST",
        headers: {

          "Access-Control-Allow-Origin": "*", // CORS header
        },
        body: formData,
      });
      if (response.ok) {
        toast.success("Form Submitted");
        setIsSubmit(true);
      } else {
        console.error("Form submission failed");
      }
    } catch (error) {
      console.error("Error:", error);
            toast.error(error);
      setIsSubmit(true);
    } finally {
      setIsLoading(false);
    }
  }
    return (
      <div id="intro1">
        <div
          id=""
          className="bg-image shadow-2-strong sell-height"
          style={{ background: "rgba(6, 12, 34, 0.537)" }}
        >
          <Header1 />
          <ToastContainer
            position="top-center"
            autoClose={3000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
            transition="Bounce"
          />
          <div className="sell mask d-flex">
            <div
              className="col-lg-9 py-6 sell-pading"
              style={{ height: "100%" }}
            >
              <div className="row justify-content-center">
                <div className="col-xl-8 col-md-10 col-sm-12 bg-white rounded">
                  <div className="section-title ">
                    <span>Property Details</span>
                    <h2>Property Details</h2>
                    <p>Your Gateway to Seamless Real Estate Transactions</p>
                  </div>
                  {!isSubmit ? (
                    <>
                      <form
                        className="bg-white rounded shadow-5-strong px-5"
                        onSubmit={handleSubmit}
                      >
                        <h5 className="text-bold">Personal Details</h5>
                        <hr />
                        <div className="d-flex flex-row">
                          <div className="mb-4 col-6">
                            <input
                              type="text"
                              name="name"
                              className="form-control"
                              placeholder="Name"
                              required
                            />
                          </div>
                          <div className="mb-4 col-6 mx-2">
                            <input
                              type="email"
                              name="email"
                              className="form-control"
                              placeholder="Email"
                              required
                            />
                          </div>
                        </div>
                        <div className="d-flex flex-row">
                          <div className="mb-4 col-6">
                            <input
                              type="number"
                              name="mob"
                              className="form-control"
                              placeholder="Mobile Number"
                              required
                            />
                          </div>
                          <div className="mb-4 col-6 mx-2">
                            <input
                              type="number"
                              name="whatsapp"
                              className="form-control"
                              placeholder="Whatsapp Number"
                              required
                            />
                          </div>
                        </div>
                        <h5 className="mt-4 text-bold">Property Details</h5>
                        <hr />
                        <div className="d-flex flex-row">
                          <div className="mb-4 col-6">
                            <select
                              name="propertyType"
                              className="form-select"
                              required
                            >
                              <option value="">Property Type</option>
                              <option value="House">House</option>
                              <option value="Apartment">Apartment</option>
                              <option value="Villa">Villa</option>
                              <option value="Land">Land</option>
                              <option value="Commercial">Commercial</option>
                            </select>
                          </div>
                          <div className="mb-4 col-6 mx-2">
                            <select
                              name="timeToSell"
                              className="form-select"
                              required
                            >
                              <option value="">Time to Sell</option>
                              <option value="immediately">Immediately</option>
                              <option value="within_1_month">
                                Within 1 Month
                              </option>
                              <option value="within_3_months">
                                Within 3 Months
                              </option>
                              <option value="within_6_months">
                                Within 6 Months
                              </option>
                              <option value="within_1_year">
                                Within 1 Year
                              </option>
                            </select>
                          </div>
                        </div>
                        <div className="d-flex flex-row">
                          <div className="mb-4 col-4">
                            <input
                              type="text"
                              name="state"
                              className="form-control"
                              placeholder="State"
                              required
                            />
                          </div>
                          <div className="mb-4 mx-2 col-4">
                            <input
                              type="text"
                              name="city"
                              className="form-control"
                              placeholder="City"
                              required
                            />
                          </div>
                          <div className="mb-4 col-4">
                            <input
                              type="text"
                              name="district"
                              className="form-control"
                              placeholder="District"
                              required
                            />
                          </div>
                        </div>
                        <div className="d-flex flex-row">
                          <div className="mb-4 col-4">
                            <input
                              type="text"
                              name="tehsil"
                              className="form-control"
                              placeholder="Tehsil"
                            />
                          </div>

                          <div className="mb-4 mx-2 col-4">
                            <input
                              type="text"
                              name="village"
                              className="form-control"
                              placeholder="Village"
                            />
                          </div>
                          <div className="mb-4 col-4">
                            <input
                              type="text"
                              name="landmark"
                              className="form-control"
                              placeholder="Landmark"
                            />
                          </div>
                        </div>
                        <div className="d-flex flex-row">
                          <div className="mb-4 col-6">
                            <input
                              type="text"
                              name="pincode"
                              className="form-control"
                              placeholder="Pincode"
                            />
                          </div>

                          <div className="mb-4 col-6 mx-2">
                            <input
                              type="number"
                              name="approximateCost"
                              className="form-control"
                              placeholder="Approximate Cost"
                              required
                            />
                          </div>
                        </div>
                        <div className="d-flex flex-row">
                          <div className="mb-4 col-4">
                            <input
                              type="number"
                              name="length"
                              className="form-control"
                              placeholder="Length (Ft.)"
                            />
                          </div>
                          <div className="mb-4 col-4 mx-2">
                            <input
                              type="number"
                              name="width"
                              className="form-control"
                              placeholder="Width (Ft.)"
                            />
                          </div>
                          <div className="mb-4 col-4">
                            <input
                              type="number"
                              name="totalAreaSqFt"
                              className="form-control"
                              placeholder="Total Area (SqFt.)"
                            />
                          </div>
                        </div>
                        <div className="d-flex flex-row">
                          <div className="mb-4 col-6">
                            <select
                              name="ownershipType"
                              className="form-select"
                            >
                              <option value="">Ownership Type</option>
                              <option value="Single">Single</option>
                              <option value="Joint">Joint</option>
                            </select>
                          </div>
                          <div className="mb-4 mx-2 col-6">
                            <input
                              type="text"
                              name="pname"
                              className="form-control"
                              placeholder="Property Holder Name"
                            />
                          </div>
                        </div>
                        <div className="mb-4">
                          <select
                            name="isLoan"
                            className="form-select"
                            onChange={handleLoanChange}
                            value={isLoan}
                          >
                            <option value="">
                              Is there any loan on the property?
                            </option>
                            <option value="No">No</option>
                            <option value="Yes">Yes</option>
                          </select>
                        </div>
                        {isLoan === "Yes" && (
                          <>
                            <div className="d-flex flex-row">
                              <div className="mb-4 col-6">
                                <input
                                  type="number"
                                  name="amount"
                                  className="form-control"
                                  placeholder="Loan Amount"
                                />
                              </div>
                              <div className="mb-4 col-6 mx-2">
                                <input
                                  type="text"
                                  name="timePeriod"
                                  className="form-control"
                                  placeholder="Loan Time Period"
                                />
                              </div>
                            </div>
                            <div className="d-flex flex-row">
                              <div className="mb-4 col-6">
                                <input
                                  type="text"
                                  name="bank"
                                  className="form-control"
                                  placeholder="Bank Name"
                                />
                              </div>
                              <div className="mb-4 col-6 mx-2">
                                <input
                                  type="text"
                                  name="bankAddress"
                                  className="form-control"
                                  placeholder="Bank Address"
                                />
                              </div>
                            </div>
                          </>
                        )}
                        <div className="mb-4">
                          <select
                            name="disputedProperty"
                            className="form-select"
                            onChange={handleDisputedPropertyChange}
                            value={disputedProperty}
                          >
                            <option value="">
                              Is this a disputed property?
                            </option>
                            <option value="Yes">Yes</option>
                            <option value="No">No</option>
                          </select>
                        </div>
                        {disputedProperty === "Yes" && (
                          <div className="mb-4">
                            <textarea
                              name="disputedPropertyDetails"
                              className="form-control"
                              placeholder="Provide details about the dispute"
                            />
                          </div>
                        )}

                        <div className="mb-4">
                          <input
                            type="file"
                            name="propertyDocuments"
                            className="form-control"
                            multiple
                            onChange={handleFileChange}
                          />
                          <small className="form-text text-muted">
                            Upload property documents
                          </small>
                        </div>
                        <div className="mb-4">
                          <textarea
                            type="text"
                            name="areaOfProperty.description"
                            className="form-control"
                            placeholder="Any other information"
                          />
                        </div>
                        <div className="row mb-4">
                          <button
                            type="submit"
                            className="btn text-blue btn-block"
                            data-mdb-ripple-init
                          >
                            {isLoading ? spinner : "Submit"}
                          </button>
                        </div>
                      </form>
                    </>
                  ) : (
                    <>
                      <h3 className="text-center">
                        Your property details have been submitted. Our team will
                        connect with you in 24 to 48 hours. Thank you for
                        trusting{" "}
                        <a
                          href="https://easyproperti.com"
                          className="text-red-link"
                        >
                          Easy Properti
                        </a>
                        , Your Premier Real Estate Partner.
                      </h3>
                    </>
                  )}
                </div>
              </div>
            </div>
            <div className="px-4 card-start">
              <div className="card-padding">
                <div className="card col-lg-3 my-6" style={{ width: "18rem" }}>
                  <div className="card-body">
                    <h4 className="card-title bg-custom py-2 text-center">
                      Selling Facts
                    </h4>
                    <p className="card-text">
                      • Homes priced accurately from the start sell for closer
                      to the asking price than those that start too high and are
                      later reduced.
                      <br />
                      <br />• On average, staged homes sell 73% faster than
                      non-staged ones.
                    </p>
                    <img
                      src="https://resumegenius.com/assets-builder/images/pelicano/contact-tips.svg"
                      alt="Illustration of person using laptop"
                      className="img-fluid"
                    />
                  </div>
                </div>
              </div>
              <div className="py-4">
                <div className="card col-lg-3 my-6" style={{ width: "18rem" }}>
                  <div className="card-body">
                    <h4 className="card-title bg-custom py-2 text-center">
                      Selling Facts
                    </h4>
                    <p className="card-text">
                      • More than 90% of buyers start their home search online,
                      emphasizing the importance of a strong online presence for
                      listings.
                      <br />
                      <br />• Homes sold through real estate agents typically
                      sell for a higher price compared to those sold by owners
                      directly.
                    </p>
                    <img
                      src="https://resumegenius.com/assets-builder/images/pelicano/contact-tips.svg"
                      alt="Illustration of person using laptop"
                      className="img-fluid"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* <Footer1 /> */}
      </div>
    );
  };

export default Sell;
