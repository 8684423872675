import React, { useEffect, useState } from "react";
import HeaderBackend2 from "../components/HeaderBackend2";
import { getToken } from "../utils/LocalStorage";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PulseLoader from "react-spinners/PulseLoader";
import Footer1 from "../components/Footer1";
import base from "../utils/base";

const PropertyView = () => {
  const [showModal, setShowModal] = useState(false);
  const [properties, setProperties] = useState([]);
  const [selectedProperty, setSelectedProperty] = useState(null);

  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const spinner = (
    <PulseLoader
      color="white"
      loading={isLoading}
      size={8}
      aria-label="Loading Spinner"
      data-testid="loader"
    />
  );

  useEffect(() => {
    // Function to fetch properties from the backend
    const fetchProperties = async () => {
      try {
        const token = getToken();

        const response = await fetch(
          `${base}/api/v1/list/user-prop?page=${page}`,
          {
            headers: {
              Authorization: `${token}`,
              "Content-Type": "application/json",
            },
          }
        );
        const data = await response.json();
        if (response.ok) {
          console.log(data);
          setProperties(data.property);
        } else {
          throw new Error("Failed to fetch properties");
        }
      } catch (error) {
        console.error("Error fetching properties:", error.message);
      }
    };

    fetchProperties();
  }, []);

  const handleShowModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handlePrev = async () => {
    try {
      const token = getToken();
      setPage(page == 1 ? 1 : page - 1);
      const response = await fetch(
        `${base}/api/v1/list/user-prop?page=${page}`,
        {
          headers: {
            Authorization: `${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      const data = await response.json();
      if (response.ok) {
        console.log(data);
        setProperties(data.property);
      } else {
        throw new Error("Failed to fetch properties");
      }
    } catch (error) {
      console.error("Error fetching properties:", error.message);
    }
  };

  const handleNext = async () => {
    try {
      const token = getToken();
      setPage(page + 1);
      const response = await fetch(
        `${base}/api/v1/list/user-prop?page=${page}`,
        {
          headers: {
            Authorization: `${token}`,
            "Content-Type": "application/json",
          },
        }
      );
      const data = await response.json();
      if (response.ok) {
        console.log(data);
        setProperties(data.property);
      } else {
        throw new Error("Failed to fetch properties");
      }
    } catch (error) {
      console.error("Error fetching properties:", error.message);
    }
  };

  const confirmDeleteProperty = async (propertyId) => {
    setIsLoading(true);
    try {
      const response = await fetch(
        `${base}/api/v1/list/prop-delete/${propertyId}`,
        {
          method: "DELETE",
        }
      );
      if (response.ok) {
        // Remove the deleted property from the properties state
        setProperties(
          properties.filter((property) => property._id !== propertyId)
        );
        handleCloseModal();
        toast.success("Property Deleted Successful");
        setIsSubmit(true);
      } else {
        throw new Error("Failed to delete property");
      }
    } catch (error) {
      console.error("Error deleting property:", error.message);
    } finally {
      setSelectedProperty(null);
    }
  };

  return (
    <div>
      <HeaderBackend2 />
      <div id="filters">
        <ToastContainer
          position="top-center"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
          transition="Bounce"
        />
        <div className="pt-4">
          <div className="section-title mt-4">
            <span>View Properties</span>
            <h2>View Properties</h2>
            <p className="text-light">View your properties here</p>
          </div>
        </div>
        <div className="container mt-2">
          <div className="row">
            <div className="col-lg-12">
              {properties.map((property, index) => {
                return (
                  <React.Fragment key={index}>
                    <div className="py-4">
                      <div className="estate">
                        <div className="container bootstrap snippets bootdey">
                          <div className="row">
                            <div className="col-md-12 icon-box">
                              <div className="row">
                                <div className="col-md-6">
                                  <div className="product-gallery">
                                    <div className="primary-image overflow-hidden">
                                      <Carousel autoPlay showThumbs={false}>
                                        {property.images.map((img, index) => {
                                          return (
                                            <>
                                              <div key={index}>
                                                <img
                                                  src={img}
                                                  className="img-responsive carousel-image"
                                                  alt=""
                                                />
                                              </div>
                                            </>
                                          );
                                        })}
                                      </Carousel>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="product-info">
                                    <h3 className="">{property.title}</h3>
                                    <h4 className="">
                                      {property.sector}
                                      {", "}
                                      {property.city}
                                      {", "}
                                      {property.state}
                                    </h4>
                                    <div className="wp-block property list no-border">
                                      <div className="wp-block-content clearfix">
                                        <p className="description mb-15">
                                          {property.desc}
                                        </p>
                                        <span className="pull-left">
                                          <span className="price">
                                            Rs. {property.price}
                                          </span>
                                        </span>
                                      </div>
                                      <div className="wp-block-footer style2 mt-15 rounded">
                                        <ul className="aux-info">
                                          <li>
                                            <i className="fa fa-building"></i>
                                            {property.area} Sq Feet
                                          </li>
                                          <li>
                                            <i className="fa fa-user"></i>{" "}
                                            {property.bedrooms} Bedrooms
                                          </li>
                                          <li>
                                            <i className="fa fa-tint"></i>{" "}
                                            {property.bathrooms} Bathrooms
                                          </li>
                                          <li>
                                            <i className="fa fa-car"></i>{" "}
                                            {property.parking} Parking
                                          </li>
                                        </ul>
                                      </div>
                                    </div>
                                    <span className="clearfix"></span>

                                    <div className="card-footer">
                                      <button
                                        className="btn text-red mt-2 btn-block col-12"
                                        onClick={handleShowModal}
                                      >
                                        Delete Property
                                      </button>
                                      {showModal && (
                                        <>
                                          <div className="modal-backdrop show"></div>
                                          <div
                                            className="modal"
                                            tabIndex="-1"
                                            role="dialog"
                                            style={{
                                              display: "block",
                                            }}
                                          >
                                            <div
                                              className="modal-dialog modal-dialog-centered"
                                              role="document"
                                            >
                                              <div className="modal-content">
                                                <div className="modal-header d-flex justify-content-between">
                                                  <h5 className="modal-title">
                                                    Are you sure you want to
                                                    Delete?
                                                  </h5>
                                                </div>
                                                <div className="modal-body d-flex justify-content-center">
                                                  <button
                                                    className="btn text-red mx-3"
                                                    onClick={() =>
                                                      confirmDeleteProperty(
                                                        property._id
                                                      )
                                                    }
                                                  >
                                                    {isLoading
                                                      ? spinner
                                                      : "Delete"}
                                                  </button>
                                                  <button
                                                    type="button"
                                                    className="close btn text-red"
                                                    onClick={handleCloseModal}
                                                  >
                                                    Cancel
                                                  </button>
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </React.Fragment>
                );
              })}
            </div>
            <div className="my-2 mb-5 pb-4 d-flex flex-row justify-content-center">
              <button className="btn btn-light mx-2" onClick={handlePrev}>
                Prev
              </button>
              <span className="text-light my-2">{page}</span>
              <button className="btn btn-light mx-2" onClick={handleNext}>
                Next
              </button>
            </div>
          </div>
        </div>
      </div>
      <Footer1 />
    </div>
  );
};

export default PropertyView;
