import React, { useEffect, useState } from "react";
import Header1 from "../components/Header1";
import Filters from "../components/Filters";
import PropertyCard from "../components/PropertyCard";
import HeaderList from "../components/HeaderList";
import base from "../utils/base";

const PropertyListPage = () => {
  const [properties, setProperties] = useState([]);
  const [filteredProperties, setFilteredProperties] = useState([]);
  const [filters, setFilters] = useState({
    propertyType: "",
    minPrice: "",
    maxPrice: "",
    location: "",
  });
  useEffect(() => {
    // Function to fetch properties from the backend
    const fetchProperties = async () => {
      try {
        // Adjust this URL to where your backend API is hosted, and the correct endpoint
        const response = await fetch(
          `${base}/api/v1/list/listing`
        );
        const data = await response.json();
        if (response.ok) {
          setProperties(data);
          setFilteredProperties(data);
        } else {
          throw new Error("Failed to fetch properties");
        }
      } catch (error) {
        console.error("Error fetching properties:", error.message);
      }
    };

    fetchProperties();
  }, []);

  useEffect(() => {
    // Apply filters when filters state changes
    applyFilters();
  }, [filters]);

  const applyFilters = () => {
    let filtered = [...properties];

    // Apply property type filter
    if (filters.propertyType) {
      filtered = filtered.filter(
        (property) => property.propertyType === filters.propertyType
      );
    }

    // Apply price range filter
    if (filters.minPrice && filters.maxPrice) {
      filtered = filtered.filter(
        (property) =>
          property.price >= parseInt(filters.minPrice) &&
          property.price <= parseInt(filters.maxPrice)
      );
    }

    // Apply location filter
    if (filters.location) {
      filtered = filtered.filter(
        (property) => property.location === filters.location
      );
    }

    setFilteredProperties(filtered);
  };

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: value,
    }));
  };

  function updateTextInput(val) {
    document.getElementById("minBudget").value = val;
  }

  function updateRangeInput(val, type) {
    if (type === "min") {
      document.getElementById("budgetRange").value = val;
    } else if (type === "max") {
      document.getElementById("maxBudget").value = val;
    }
  }
  return (
    <div>
      <HeaderList />
      <Filters />
    </div>
  );
};

export default PropertyListPage;
