import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PulseLoader from "react-spinners/PulseLoader";

const PropertyCard = ({ property }) => {
  const [showModal, setShowModal] = useState(false);

  const handleShowModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };
  return (
    <div className="estate my-4">
      <div className="container bootstrap snippets bootdey">
        <div className="row">
          <div className="col-md-12 icon-box">
            <div className="row">
              <div className="col-md-6">
                <div className="product-gallery">
                  <div className="primary-image overflow-hidden">
                    <Carousel autoPlay showThumbs={false}>
                      {property.images.map((img, index) => {
                        return (
                          <>
                            <div key={index}>
                              <img
                                src={img}
                                className="img-responsive carousel-image"
                                alt=""
                              />
                            </div>
                          </>
                        );
                      })}
                    </Carousel>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="product-info">
                  <h3 className="">{property.title}</h3>
                  <h4 className="">
                    {property.sector}
                    {", "}
                    {property.city}
                    {", "}
                    {property.state}
                  </h4>
                  <div className="wp-block property list no-border">
                    <div className="wp-block-content clearfix">
                      <p className="description mb-15">{property.desc}</p>
                      <span className="pull-left">
                        <span className="price">Rs. {property.price}</span>
                        {/* <span className="period">per month</span> */}
                      </span>
                      {/* <span className="pull-right">
                        <span className="capacity">
                          <i className="fa fa-user"></i>
                          <i className="fa fa-user"></i>
                        </span>
                      </span> */}
                    </div>
                    <div className="wp-block-footer style2 mt-15 rounded">
                      <ul className="aux-info">
                        <li>
                          <i className="fa fa-building"></i>
                          {property.area} Sq Feet
                        </li>
                        <li>
                          <i className="fa fa-user"></i> {property.bedrooms}{" "}
                          Bedrooms
                        </li>
                        <li>
                          <i className="fa fa-tint"></i> {property.bathrooms}{" "}
                          Bathrooms
                        </li>
                        <li>
                          <i className="fa fa-car"></i> {property.parking}{" "}
                          Parking
                        </li>
                      </ul>
                    </div>
                  </div>
                  <span className="clearfix"></span>

                  <div className="card-footer">
                    <button
                      className="btn text-red mt-2 btn-block col-12"
                      onClick={handleShowModal}
                    >
                      Contact
                    </button>
                  </div>
                  {showModal && (
                    <>
                      <div className="modal-backdrop show"></div>
                      <div
                        className="modal"
                        tabIndex="-1"
                        role="dialog"
                        style={{
                          display: "block",
                        }}
                      >
                        <div
                          className="modal-dialog modal-dialog-centered"
                          role="document"
                        >
                          <div className="modal-content">
                            <div className="modal-header d-flex justify-content-between">
                              <h5 className="modal-title">
                                Thank you for trusting Easy Properti!
                              </h5>
                              <button
                                type="button"
                                className="close btn text-red"
                                onClick={handleCloseModal}
                              >
                                <span aria-hidden="true">&times;</span>
                              </button>
                            </div>
                            <div className="modal-body d-flex justify-content-center flex-wrap">

                              <a
                                className="btn text-red mx-3"
                                href="tel:+91 9416255871"
                              >
                                +91 9416255871
                              </a>
                              <a
                                className="btn text-red mx-3"
                                href="tel:+91 9416255871"
                              >
                                +91 9416255871
                              </a>
                              <a
                                href="mailto:info.easyproperti@gmail.com"
                                className="close btn text-red my-3"
                              >
                                info.easyproperti@gmail.com
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PropertyCard;
