import React, { useState } from "react";
import { Link } from "react-router-dom";
import { storeToken } from "../utils/LocalStorage";
import Header from "../components/Header";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PulseLoader from "react-spinners/PulseLoader";
import HeaderLogin from "../components/HeaderLogin";
import axios from "axios";
import Footer1 from "../components/Footer1";
import base from "../utils/base";

const Register = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [mob, setMob] = useState("");
  const [password, setPassword] = useState("");
  const [cPassword, setCPassword] = useState("");
  const [altMob, setAltMob] = useState("");
  const [adhaar, setAdhaar] = useState("");
  const [certificate, setCertificate] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const spinner = (
    <PulseLoader
      color="white"
      loading={isLoading}
      size={8}
      aria-label="Loading Spinner"
      data-testid="loader"
    />
  );

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    try {
      const formData = new FormData();
      formData.append("name", name);
      formData.append("email", email);
      formData.append("mob", mob);
      formData.append("altMob", altMob);
      formData.append("password", password);
      formData.append("adhaar", adhaar);
  
      // Only append the certificate if it's provided by the user
      if (certificate) {
        formData.append("certificate", certificate);
      }
  
      console.log(formData);
      const response = await axios.post(
        `${base}/api/v1/user/register`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            "Access-Control-Allow-Origin": "*",
          },
        }
      );
  
      // Assuming successful registration, you can redirect or handle the response here
      console.log(response.data); // Newly created user data
      storeToken(response.data.token, 432000);
      toast.success("Registration Successful");
      setIsSubmit(true);
      storeToken(response.data.token, 432000);
      window.location.href = "/login";
      // Redirect or handle successful registration response
    } catch (error) {
      console.error("Registration failed:", error.message);
      toast.error("Registration Failed");
    } finally {
      setIsLoading(false);
    }
  };
  

  return (
    <div id="hero" className="register-hero">
      <HeaderLogin text={"Login"} />
      <div className="register position-relative hero-img">
        <ToastContainer
          position="top-center"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
          transition="Bounce"
        />
        <div className="col-5"></div>

        <div className="register-pattern">
          <img
            src="images/bg-pattern.png"
            alt=""
            width="1240"
            height="840"
            role="presentation"
            class="bg-pattern"
          />
          <img
            src="images/hero.png"
            alt=""
            width="816"
            height="659"
            role="presentation"
            class="hero-banner"
          />
        </div>
      </div>
      <div className="register-form col-5 mt-5 pt-4">
        <div className="px-4">
          <div className="row justify-content-center">
            <div className="">
              <form
                className="bg-white rounded shadow-5-strong p-3"
                onSubmit={handleSubmit}
              >
                <h3 className="text-center mb-4">Register to Easy Properti</h3>
                <div className="form-content">
                  <div className="form-outline mb-4 px-1" data-mdb-input-init>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Name"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      required
                    />
                  </div>

                  <div className="form-outline mb-4 px-1" data-mdb-input-init>
                    <input
                      type="email"
                      placeholder="Email"
                      className="form-control"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      required
                    />
                  </div>
                </div>
                <div className="form-content">
                  <div className="form-outline mb-4 px-1" data-mdb-input-init>
                    <input
                      type="number"
                      placeholder="Mobile Number"
                      className="form-control"
                      value={mob}
                      onChange={(e) => setMob(e.target.value)}
                      required
                    />
                  </div>
                  <div className="form-outline mb-4 px-1" data-mdb-input-init>
                    <input
                      type="number"
                      className="form-control"
                      placeholder="Alternate Mobile Number"
                      value={altMob}
                      onChange={(e) => setAltMob(e.target.value)}
                      required
                    />
                  </div>
                </div>
                <div className="form-content">
                  <div className="form-outline mb-4 px-1" data-mdb-input-init>
                    <input
                      type="password"
                      className="form-control"
                      value={password}
                      placeholder="Password"
                      onChange={(e) => setPassword(e.target.value)}
                      required
                    />
                    {/* <label className="form-label">Password</label> */}
                  </div>
                  <div className="form-outline mb-4 px-1" data-mdb-input-init>
                    <input
                      type="password"
                      className="form-control"
                      value={cPassword}
                      placeholder="Confirm Password"
                      onChange={(e) => setCPassword(e.target.value)}
                      required
                    />
                    {/* <label className="form-label">Password</label> */}
                  </div>
                </div>
                <div className="form-content">
                  <div className="form-outline mb-4 px-1">
                    <small className="form-text text-muted">
                      Upload Identity Proof
                    </small>
                    <input
                      type="file"
                      name="adhaar"
                      className="form-control"
                      onChange={(e) => setAdhaar(e.target.files[0])}
                      required
                    />
                    <small className="form-text text-muted">
                      Image only (.jpg, .jpeg, .png)
                    </small>
                  </div>
                  <div className="form-outline mb-4 px-1">
                    <small className="form-text text-muted">
                      Upload Dealer Registration Certificate
                    </small>
                    <input
                      type="file"
                      name="certificate"
                      className="form-control"
                      onChange={(e) => setCertificate(e.target.files[0])}
                    />
                    <small className="form-text text-muted">
                      Image only (.jpg, .jpeg, .png)
                    </small>
                  </div>
                </div>

                <div className="row px-4 mb-4">
                  <button
                    type="submit"
                    className="btn btn-custom1 text-red"
                    data-mdb-ripple-init
                  >
                    {isLoading ? spinner : "Register"}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <Footer1 />
    </div>
  );
};

export default Register;
