import React, { useState } from "react";
import axios from "axios"; // Import Axios
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PulseLoader from "react-spinners/PulseLoader";
import { getToken } from "../utils/LocalStorage";
import HeaderBackend from "../components/HeaderBackend";
import Footer from "../components/Footer";
import base from "../utils/base";

const Upload = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({
    propertyType: "",
    area: "",
    sector: "",
    city: "",
    district: "",
    state: "",
    pincode: "",
    title: "",
    desc: "",
    price: "",
    bedrooms: "",
    bathrooms: "",
    parking: "",
    // reference: "",
    // referenceNumber: "",
    files: null,
    images: null,
    moreInfo: "",
  });

  const spinner = (
    <PulseLoader
      color="white"
      loading={isLoading}
      size={8}
      aria-label="Loading Spinner"
      data-testid="loader"
    />
  );

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleFileChange = (e) => {
    const name = e.target.name;
    const files = Array.from(e.target.files);

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: files,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    const data = new FormData();
    for (const key in formData) {
      if (formData.hasOwnProperty(key)) {
        if (Array.isArray(formData[key])) {
          // If the value is an array, append each file separately
          formData[key].forEach((file, index) => {
            data.append(`${key}`, file);
          });
        } else {
          // Otherwise, append as usual
          data.append(key, formData[key]);
        }
      }
    }

    console.log(data);

    try {
      const response = await axios.post(`${base}/api/v1/list/add-prop`, data, {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `${getToken()}`,
        },
      });
      setIsLoading(false);
      toast.success(response.data.message);
      window.location.href = "/property-view";
    } catch (error) {
      setIsLoading(false);
      toast.error("Failed to create property");
      console.error(error.response.data);
    }
  };

  return (
    <div id="intro1">
      <div
        id=""
        className="bg-image shadow-2-strong buy-height"
        style={{ background: "rgba(6, 12, 34, 0.537)" }}
      >
        <HeaderBackend link={"View"} />
        <ToastContainer
          position="top-center"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
          // transition="bounce"
        />
        <div className="sell mask d-flex">
          <div className="col-lg-9 py-6 sell-pading" style={{ height: "100%" }}>
            <div className="row justify-content-center">
              <div className="col-xl-8 col-md-10 col-sm-12 bg-white">
                <div className="section-title">
                  <span>Property Upload</span>
                  <h2>Property Upload</h2>
                  <p>Your Gateway to Seamless Real Estate Transactions</p>
                </div>
                <form
                  className="bg-white rounded shadow-5-strong p-5"
                  onSubmit={handleSubmit}
                  encType="multipart/form-data"
                >
                  <div className="d-flex flex-row">
                    <div className="mb-4 col-6 mx-2">
                      <select
                        name="propertyType"
                        className="form-select"
                        value={formData.propertyType}
                        onChange={handleChange}
                        required
                      >
                        <option value="">Property Type</option>
                        <option value="House">House</option>
                        <option value="Apartment">Apartment/Flat</option>
                        <option value="Villa">Villa</option>
                        <option value="Land">Land</option>
                        <option value="Commercial">Commercial</option>
                      </select>
                    </div>
                    <div className="mb-4 col-6">
                      <input
                        type="text"
                        name="area"
                        className="form-control"
                        placeholder="Area (in sq. ft.)"
                        value={formData.area}
                        onChange={handleChange}
                        required
                      />
                    </div>
                  </div>
                  <div className="d-flex flex-row">
                    <div className="mb-4 col-4 mx-1">
                      <input
                        type="text"
                        name="sector"
                        className="form-control"
                        placeholder="Sector"
                        value={formData.sector}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <div className="mb-4 col-4 mx-1">
                      <input
                        type="text"
                        name="city"
                        className="form-control"
                        placeholder="City"
                        value={formData.city}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <div className="mb-4 col-4 mx-1">
                      <input
                        type="text"
                        name="district"
                        className="form-control"
                        placeholder="District"
                        value={formData.district}
                        onChange={handleChange}
                        required
                      />
                    </div>
                  </div>
                  <div className="d-flex flex-row">
                    <div className="mb-4 col-6 mx-2">
                      <input
                        type="text"
                        name="state"
                        className="form-control"
                        placeholder="State"
                        value={formData.state}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <div className="mb-4 col-6">
                      <input
                        type="text"
                        name="pincode"
                        className="form-control"
                        placeholder="Pincode"
                        value={formData.pincode}
                        onChange={handleChange}
                      />
                    </div>
                  </div>

                  <div className="d-flex flex-row">
                    <div className="mb-4 col-4 mx-1">
                      <input
                        type="text"
                        name="title"
                        className="form-control"
                        placeholder="Title"
                        value={formData.title}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <div className="mb-4 col-4 mx-1">
                      <input
                        type="text"
                        name="desc"
                        className="form-control"
                        placeholder="Description"
                        value={formData.desc}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="mb-4 col-4 mx-1">
                      <input
                        type="text"
                        name="price"
                        className="form-control"
                        placeholder="Price"
                        value={formData.price}
                        onChange={handleChange}
                      />
                    </div>
                  </div>

                  <div className="d-flex flex-row">
                    <div className="mb-4 col-4 mx-1">
                      <input
                        type="text"
                        name="bedrooms"
                        className="form-control"
                        placeholder="Bedrooms"
                        value={formData.bedrooms}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <div className="mb-4 col-4 mx-1">
                      <input
                        type="text"
                        name="bathrooms"
                        className="form-control"
                        placeholder="Bathrooms"
                        value={formData.bathrooms}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <div className="mb-4 col-4 mx-1">
                      <input
                        type="text"
                        name="parking"
                        className="form-control"
                        placeholder="Parking"
                        value={formData.parking}
                        onChange={handleChange}
                        required
                      />
                    </div>
                  </div>
                  {/* <div className="d-flex flex-row">
                    <div className="mb-4 col-6 mx-2">
                      <input
                        type="text"
                        name="reference"
                        className="form-control"
                        placeholder="Reference Name"
                        value={formData.reference}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <div className="mb-4 col-6">
                      <input
                        type="text"
                        name="referenceNumber"
                        className="form-control"
                        placeholder="Reference Number"
                        value={formData.refernceNumber}
                        onChange={handleChange}
                      />
                    </div>
                  </div> */}

                  <div className="mb-4">
                    <input
                      type="file"
                      name="images"
                      className="form-control"
                      multiple
                      onChange={handleFileChange}
                    />
                    <small className="form-text text-muted">
                      Upload property images (Max. 5 Images)
                    </small>
                  </div>
                  <div className="mb-4">
                    <input
                      type="file"
                      name="files"
                      className="form-control"
                      multiple
                      onChange={handleFileChange}
                    />
                    <small className="form-text text-muted">
                      Upload property documents (.pdf only)
                    </small>
                  </div>
                  <div className="mb-4">
                    <textarea
                      type="text"
                      name="moreInfo"
                      className="form-control"
                      placeholder="Any other information"
                      value={formData.moreInfo}
                      onChange={handleChange}
                    />
                  </div>

                  <div className="row mb-4">
                    <button
                      type="submit"
                      className="btn btn-block text-red"
                      data-mdb-ripple-init
                    >
                      {isLoading ? spinner : "Submit"}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <div className="px-4 card-start">
            <div className="card-padding">
              <div className="card col-lg-3 my-6" style={{ width: "18rem" }}>
                <div className="card-body">
                  <h4 className="card-title bg-custom py-2 text-center">
                    Buying Facts
                  </h4>
                  <p className="card-text">
                    • Hire a professional inspector to thoroughly examine the
                    property for any issues or potential problems before making
                    an offer.
                    <br />
                    <br />• On average, homeowners' net worth is significantly
                    higher than that of renters.
                  </p>
                  <img
                    src="https://resumegenius.com/assets-builder/images/pelicano/contact-tips.svg"
                    alt="Illustration of person using laptop"
                    className="img-fluid"
                  />
                </div>
              </div>
            </div>
            <div className="py-4">
              <div className="card col-lg-3 my-6" style={{ width: "18rem" }}>
                <div className="card-body">
                  <h4 className="card-title bg-custom py-2 text-center">
                    Buying Facts
                  </h4>
                  <p className="card-text">
                    • Think about your long-term plans and whether the property
                    meets your future needs, such as space for a growing family
                    or accessibility as you age.
                    <br />
                    <br />• Read and understand all terms and conditions of the
                    purchase contract before signing. Consider seeking legal
                    advice if needed.
                  </p>
                  <img
                    src="https://resumegenius.com/assets-builder/images/pelicano/contact-tips.svg"
                    alt="Illustration of person using laptop"
                    className="img-fluid"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Upload;
