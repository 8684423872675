import React, { useState, useEffect } from "react";
import axios from "axios";
import PropertyCard from "./PropertyCard";
import MultiRangeSlider from "../multiRangeSlider/MultiRangeSlider";
import Footer1 from "../components/Footer1";
import base from "../utils/base";

const Filters = () => {
  const [priceRange, setPriceRange] = useState({ min: 0, max: 100000000 });
  const [areaRange, setAreaRange] = useState({ min: 0, max: 10000 });
  const [bedrooms, setBedrooms] = useState([]);
  const [sector, setSector] = useState([]);
  const [city, setCity] = useState([]);
  const [propertyType, setPropertyType] = useState([]);
  const [filteredProperties, setFilteredProperties] = useState([]);
  const [location1, setLocation1] = useState([]);
  const [propertyType1, setPropertyType1] = useState([]);

  useEffect(() => {
    fetch(`${base}/api/v1/list/locations`)
      .then((response) => response.json())
      .then((data) => setLocation1(data))
      .catch((error) => console.error("Error fetching locations:", error));

    fetch(`${base}/api/v1/list/types`)
      .then((response) => response.json())
      .then((data) => setPropertyType1(data))

      .catch((error) => console.error("Error fetching property types:", error));

    applyFilters();
  }, []);

  const applyFilters = () => {
    const localLocation = localStorage.getItem("Location");
    const localPropertyType = localStorage.getItem("Property");

    // Check if either localLocation or localPropertyType is not null
    if (localLocation !== null || localPropertyType !== null) {
      let url = `${base}/api/v1/list/listing?`;

      // Construct URL based on presence of localLocation and localPropertyType
      if (localPropertyType) {
        url += `propertyType=${localPropertyType}`;
      }
      if (localLocation) {
        // If both propertyType and location are present, add '&' before adding location
        if (localPropertyType) {
          url += `&city=${localLocation}`;
        } else {
          url += `city=${localLocation}`;
        }
      }

      console.log(url);

      axios
        .get(url)
        .then((response) => {
          console.log("Filtered properties 1:", response.data);
          setFilteredProperties(response.data.property || []);
        })
        .catch((error) => {
          console.error("Error fetching filtered properties:", error);
        });
    } else {
      const filters = {};

      // Price Range
      if (priceRange.min !== "" && priceRange.max !== "") {
        filters.price = { gte: priceRange.min, lte: priceRange.max };
      }

      // Area Range
      if (areaRange.min !== "" && areaRange.max !== "") {
        filters.area = { gte: areaRange.min, lte: areaRange.max };
      }

      // Bedrooms
      if (bedrooms.length > 0) {
        filters.bedrooms = bedrooms;
      }

      // Sector
      if (sector.length > 0) {
        filters.sector = sector;
      }

      // city
      if (city.length > 0) {
        filters.city = city;
      }

      // propertyType
      if (propertyType.length > 0) {
        filters.propertyType = propertyType;
      }

      const queryString = Object.keys(filters)
        .map((key) => {
          if (typeof filters[key] === "object") {
            return Object.keys(filters[key])
              .map(
                (innerKey) => `${key}[${innerKey}]=${filters[key][innerKey]}`
              )
              .join("&");
          } else {
            return `${key}=${filters[key]}`;
          }
        })
        .join("&");

      console.log("Query String:", queryString);
      axios
        .get(
          `${base}/api/v1/list/listing${queryString ? `?${queryString}` : ""}`
        )
        .then((response) => {
          console.log("Filtered properties:", response.data);
          setFilteredProperties(response.data.property || []);
        })
        .catch((error) => {
          console.error("Error fetching filtered properties:", error);
        });
    }
  };

  const handleBedroomCheckbox = (e) => {
    const { value, checked } = e.target;
    if (checked) {
      setBedrooms([...bedrooms, value]);
    } else {
      setBedrooms(bedrooms.filter((item) => item !== value));
    }
  };

  const handleLocationCheckbox = (e) => {
    const { value, checked } = e.target;
    if (checked) {
      setCity([...city, value]);
    } else {
      setCity(city.filter((item) => item !== value));
    }
  };

  const handlePropertyTypeCheckbox = (e) => {
    const { value, checked } = e.target;
    if (checked) {
      setPropertyType([...propertyType, value]);
    } else {
      setPropertyType(propertyType.filter((item) => item !== value));
    }
  };

  return (
    <div id="filters">
      <div className="container pt-4 filters">
        <div className="row">
          {/* Filter UI elements */}
          <div className="col-lg-3 bg-light filter-card rounded">
            <div className="section-title">
              <span>Filters</span>
              <h2>Filters</h2>
              <p>Apply filters to get filtered properties</p>
            </div>
            <div className="form-group">
              <label htmlFor="priceRange">Price Range:</label>
              <div className="d-flex flex-row justify-content-center my-4 pb-4">
                <input
                  type="number"
                  className="form-control"
                  id="minPrice"
                  value={priceRange.min}
                  onChange={(e) =>
                    setPriceRange({ ...priceRange, min: e.target.value })
                  }
                  placeholder="Min Price"
                />
                <input
                  type="number"
                  className="form-control"
                  id="maxPrice"
                  value={priceRange.max}
                  onChange={(e) =>
                    setPriceRange({ ...priceRange, max: e.target.value })
                  }
                  placeholder="Max Price"
                />
              </div>
            </div>
            <div className="form-group my-4">
              <label htmlFor="sector">Location:</label>
              {location1.map((loc, index) => {
                return (
                  <div className="" key={index}>
                    <label className="bedrooms filter-button">
                      <div className="d-flex flex-row align-items-center justify-content-center align-items-center">
                        <input
                          type="checkbox"
                          name="city"
                          value={loc}
                          onChange={handleLocationCheckbox}
                        />
                        <span className="px-2">
                          <PlusIcon className="text-dark" />
                          <span className="bedrooms-title">{loc}</span>
                        </span>
                      </div>
                    </label>
                  </div>
                );
              })}
            </div>
            <div className="form-group my-4">
              <label htmlFor="propertyType">Property Type:</label>
              {propertyType1.map((loc, index) => {
                return (
                  <div className="" key={index}>
                    <label className="bedrooms filter-button">
                      <div className="d-flex flex-row align-items-center justify-content-center align-items-center">
                        <input
                          type="checkbox"
                          name="propertyType"
                          value={loc}
                          onChange={handlePropertyTypeCheckbox}
                        />
                        <span className="px-2">
                          <PlusIcon className="text-dark" />
                          <span className="bedrooms-title">{loc}</span>
                        </span>
                      </div>
                    </label>
                  </div>
                );
              })}
            </div>
            <div className="form-group my-4">
              <label htmlFor="areaRange">Area Range:</label>
              <div className="d-flex flex-row justify-content-center my-4 pb-4">
                <input
                  type="number"
                  className="form-control"
                  id="minArea"
                  value={areaRange.min}
                  onChange={(e) =>
                    setAreaRange({ ...areaRange, min: e.target.value })
                  }
                  placeholder="Min Area"
                />
                <input
                  type="number"
                  className="form-control"
                  id="maxArea"
                  value={areaRange.max}
                  onChange={(e) =>
                    setAreaRange({ ...areaRange, max: e.target.value })
                  }
                  placeholder="Max Area"
                />
              </div>
            </div>
            <div className="form-group my-4">
              <label htmlFor="bedrooms">Bedrooms:</label>
              <div className="d-flex flex-row flex-wrap">
                <label className="bedrooms filter-button mx-2">
                  <div>
                    <input
                      type="checkbox"
                      name="bedrooms"
                      value={1}
                      onChange={handleBedroomCheckbox}
                    />
                    <span className="px-2 py-1">
                      <PlusIcon className="text-dark" />
                      <span className="bedrooms-title"> 1BHK</span>
                    </span>
                  </div>
                </label>
                <label className="bedrooms filter-button mx-2">
                  <div>
                    <input
                      type="checkbox"
                      name="bedrooms"
                      value={2}
                      onChange={handleBedroomCheckbox}
                    />
                    <span className="px-2 py-1">
                      <PlusIcon className="text-dark" />
                      <span className="bedrooms-title"> 2BHK</span>
                    </span>
                  </div>
                </label>
                <label className="bedrooms filter-button mx-2">
                  <div>
                    <input
                      type="checkbox"
                      name="bedrooms"
                      value={3}
                      onChange={handleBedroomCheckbox}
                    />
                    <span className="px-2 py-1">
                      <PlusIcon className="text-dark" />
                      <span className="bedrooms-title"> 3BHK</span>
                    </span>
                  </div>
                </label>
                <label className="bedrooms filter-button mx-2">
                  <div>
                    <input
                      type="checkbox"
                      name="bedrooms"
                      value={4}
                      onChange={handleBedroomCheckbox}
                    />
                    <span className="px-2 py-1">
                      <PlusIcon className="text-dark" />
                      <span className="bedrooms-title"> 4BHK</span>
                    </span>
                  </div>
                </label>
                <label className="bedrooms filter-button mx-2">
                  <div>
                    <input
                      type="checkbox"
                      name="bedrooms"
                      value={5}
                      onChange={handleBedroomCheckbox}
                    />
                    <span className="px-2 py-1">
                      <PlusIcon className="text-dark" />
                      <span className="bedrooms-title"> 5BHK+</span>
                    </span>
                  </div>
                </label>
              </div>
            </div>

            <button
              className="btn btn-custom1 text-red my-4"
              onClick={applyFilters}
            >
              Apply Filters
            </button>
          </div>
          <div className="col-lg-9">
            {filteredProperties.map((property, index) => (
              <React.Fragment key={index}>
                <PropertyCard property={property} />
              </React.Fragment>
            ))}
          </div>
        </div>
      </div>
      <Footer1 />
    </div>
  );
};

function PlusIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 100 100"
    >
      <rect x="20" y="45" width="60" height="10" fill="black" />
      <rect x="45" y="20" width="10" height="60" fill="black" />
    </svg>
  );
}

export default Filters;
