import React from "react";
import { Navigate, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import Buy from "./pages/Buy";
import Sell from "./pages/Sell";
import PropertyListPage from "./pages/PropertyListPage";
import PropertyCardDetail from "./components/PropertyCardDetail";
import Upload from "./pages/Upload";
import Login from "./pages/Login";
import Register from "./pages/Register";
import { getToken } from "./utils/LocalStorage";
import PropertyView from "./pages/PropertyView";
import ReactGA from "react-ga";
ReactGA.initialize("G-TMKQBCHSM1");

const App = () => {
  const token = getToken();
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/buy" element={<Buy />} />
      <Route path="/sell" element={<Sell />} />
      <Route path="/property-list" element={<PropertyListPage />} />
      <Route path="/property-detail" element={<PropertyCardDetail />} />
      <Route
        path="/property-view"
        element={token ? <PropertyView /> : <Navigate to="/login" />}
      />
      <Route
        path="/property-upload"
        element={token ? <Upload /> : <Navigate to="/login" />}
      />
      <Route path="/register" element={<Register />} />
      <Route
        path="/login"
        element={!token ? <Login /> : <Navigate to="/property-view" />}
      />
    </Routes>
  );
};

export default App;
