import React, { useState } from "react";
import base from "../utils/base";

const Cta = () => {
  const [showModal, setShowModal] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    mobileNumber: "",
    location: "",
  });

  const handleShowModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(`${base}/api/v1/add-agent`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });
      if (response.ok) {
        alert("Agent added successfully!");
        handleCloseModal();
      } else {
        const data = await response.json();
        throw new Error(data.message);
      }
    } catch (error) {
      alert("Failed to add agent: " + error.message);
    }
  };

  return (
    <section id="cta" className="cta">
      <div className="container" data-aos="zoom-in">
        <div className="text-center">
          <h3>Be a part of our growing real state agents</h3>
          <p>Join our team of real estate agents and be part of our growth!</p>
          <button className="text-red btn" onClick={handleShowModal}>
            Call for Agents
          </button>
        </div>
      </div>

      {showModal && (
        <>
          <div className="modal-backdrop show"></div>
          <div
            className="modal"
            tabIndex="-1"
            role="dialog"
            style={{ display: "block" }}
          >
            <div className="modal-dialog" role="document">
              <div className="modal-content">
                <div className="modal-header d-flex justify-content-between">
                  <h5 className="modal-title">Call for Agents</h5>
                  <button
                    type="button"
                    className="close btn text-red"
                    onClick={handleCloseModal}
                  >
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div className="modal-body">
                  <p className="text-dark text-center">
                    Contact us to join our team of real estate agents!
                  </p>
                  <form className="px-4" onSubmit={handleSubmit}>
                    <div className="form-outline mb-2" data-mdb-input-init>
                      <label className="form-label" htmlFor="name">
                        Name
                      </label>
                      <input
                        type="text"
                        id="name"
                        className="form-control"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="form-outline mb-2" data-mdb-input-init>
                      <label className="form-label" htmlFor="email">
                        Email
                      </label>
                      <input
                        type="email"
                        id="email"
                        className="form-control"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="form-outline mb-2" data-mdb-input-init>
                      <label className="form-label" htmlFor="mobileNumber">
                        Mobile Number
                      </label>
                      <input
                        type="text"
                        id="mobileNumber"
                        className="form-control"
                        name="mobileNumber"
                        value={formData.mobileNumber}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="form-outline mb-2" data-mdb-input-init>
                      <label className="form-label" htmlFor="location">
                        Location you deal in
                      </label>
                      <input
                        type="text"
                        id="location"
                        className="form-control"
                        name="location"
                        value={formData.location}
                        onChange={handleChange}
                      />
                    </div>

                    <div className="modal-footer">
                      <button type="submit" className="btn text-red">
                        Submit
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </section>
  );
};

export default Cta;
