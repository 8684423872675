import React from "react";
import { Link } from "react-router-dom";

const Header = ({ mainRef, aboutRef, serviceRef, contactRef }) => {
  const handleScroll = (ref) => {
    window.scrollTo({
      top: ref.offsetTop,
      left: 0,
      behavior: "smooth",
    });
    const div = document.querySelector("#navbar");
    div.classList.add("navbar");
    div.classList.remove("navbar-mobile");
  };
  function mobileNavView() {
    const div = document.querySelector("#navbar");
    div.classList.remove("navbar");
    div.classList.toggle("navbar-mobile");
  }
  return (
    <div>
      <nav className="navbar navbar-expand-lg navbar-dark shadow-5-strong">
        <div className="container-fluid">
          <div className="container d-flex flex-row justify-content-between">
            <div className="d-flex flex-row justify-content-between align-items-center">
              <Link className="navbar-brand" href="#">
                {/* <h3>Easy Properti</h3> */}
                <img src="images/logo-light.png" className="header-img" />
              </Link>
              <button onClick={mobileNavView} className="mobile-nav-toggle">
                <img
                  width="30"
                  height="30"
                  src="https://img.icons8.com/ios-glyphs/30/FFFFFF/menu--v1.png"
                  alt="menu--v1"
                />
              </button>
            </div>
            <div id="navbar" className="flex navbar">
              <div>
                <ul className="navbar-nav me-auto mt-4 mb-2 mb-lg-0">
                  <li className="nav-item">
                    <Link
                      className="nav-link active"
                      aria-current="page"
                      onClick={() => {
                        handleScroll(mainRef.current);
                      }}
                    >
                      Home
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      className="nav-link active"
                      aria-current="page"
                      onClick={() => {
                        handleScroll(aboutRef.current);
                      }}
                    >
                      About
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      className="nav-link active"
                      aria-current="page"
                      onClick={() => {
                        handleScroll(serviceRef.current);
                      }}
                    >
                      Services
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link
                      className="nav-link active"
                      aria-current="page"
                      onClick={() => {
                        handleScroll(contactRef.current);
                      }}
                    >
                      Contact
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link active" to="/buy">
                      <button className="btn-custom text-red mx-2">Buy</button>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link active" to="/sell">
                      <button className="btn-custom text-red mx-2">Sell</button>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link active" to="/login">
                      <button className="btn-custom text-red mx-2">
                        Dealer Login
                      </button>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link active" to="/register">
                      <button className="btn-custom text-red mx-2">
                        Dealer Register
                      </button>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Header;
