import React from "react";
import { Link } from "react-router-dom";

const Footer = ({ mainRef, aboutRef, serviceRef, contactRef }) => {
  const handleScroll = (ref) => {
    window.scrollTo({
      top: ref.offsetTop,
      left: 0,
      behavior: "smooth",
    });
  };
  return (
    <div>
      <footer id="footer">
        <div className="footer-top">
          <div className="container">
            <div className="row">
              <div className="col-lg-4 col-md-6">
                <div className="footer-info">
                  <img src="images/logo-light.png" className="footer-img" />
                  {/* <p>
                    A108 Adam Street <br />
                    NY 535022, USA
                    <br />
                    <br />
                    <strong>Phone:</strong> +1 5589 55488 55
                    <br />
                    <strong>Email:</strong> info@example.com
                    <br />
                  </p> */}
                  <div className="social-links mt-3">
                    <a href="#" className="twitter">
                      <img
                        width="24"
                        height="24"
                        src="https://img.icons8.com/material/24/cc1616/twitter--v2.png"
                        alt="twitter--v2"
                      />
                    </a>
                    <a href="#" className="facebook">
                      <img
                        width="24"
                        height="24"
                        src="https://img.icons8.com/material/24/cc1616/facebook-new.png"
                        alt="facebook-new"
                      />
                    </a>
                    <a href="#" className="instagram">
                      <img
                        width="24"
                        height="24"
                        src="https://img.icons8.com/material/24/cc1616/instagram-new--v1.png"
                        alt="instagram-new--v1"
                      />
                    </a>
                    <a href="#" className="linkedin">
                      <img
                        width="24"
                        height="24"
                        src="https://img.icons8.com/material/24/cc1616/linkedin--v1.png"
                        alt="linkedin--v1"
                      />
                    </a>
                  </div>
                </div>
              </div>

              <div className="col-lg-2 col-md-6 footer-links">
                <h4>Useful Links</h4>
                <ul>
                  <li>
                    <img
                      width="20"
                      height="20"
                      src="https://img.icons8.com/ios-glyphs/30/cc1616/checkmark--v1.png"
                      alt="checkmark--v1"
                    />{" "}
                    <Link
                      onClick={() => {
                        handleScroll(mainRef.current);
                      }}
                    >
                      Home
                    </Link>
                  </li>
                  <li>
                    <img
                      width="20"
                      height="20"
                      src="https://img.icons8.com/ios-glyphs/30/cc1616/checkmark--v1.png"
                      alt="checkmark--v1"
                    />{" "}
                    <Link
                      onClick={() => {
                        handleScroll(aboutRef.current);
                      }}
                    >
                      About us
                    </Link>
                  </li>
                  <li>
                    <img
                      width="20"
                      height="20"
                      src="https://img.icons8.com/ios-glyphs/30/cc1616/checkmark--v1.png"
                      alt="checkmark--v1"
                    />{" "}
                    <Link
                      onClick={() => {
                        handleScroll(serviceRef.current);
                      }}
                    >
                      Services
                    </Link>
                  </li>
                  <li>
                    <img
                      width="20"
                      height="20"
                      src="https://img.icons8.com/ios-glyphs/30/cc1616/checkmark--v1.png"
                      alt="checkmark--v1"
                    />{" "}
                    <a href="#">Terms of service</a>
                  </li>
                  <li>
                    <img
                      width="20"
                      height="20"
                      src="https://img.icons8.com/ios-glyphs/30/cc1616/checkmark--v1.png"
                      alt="checkmark--v1"
                    />{" "}
                    <a href="#">Privacy policy</a>
                  </li>
                </ul>
              </div>

              <div className="col-lg-4 col-md-6 footer-links">
                <h4>Our Services</h4>
                <ul>
                  <li>
                    <img
                      width="20"
                      height="20"
                      src="https://img.icons8.com/ios-glyphs/30/cc1616/checkmark--v1.png"
                      alt="checkmark--v1"
                    />{" "}
                    Buying and Selling
                  </li>
                  <li>
                    <img
                      width="20"
                      height="20"
                      src="https://img.icons8.com/ios-glyphs/30/cc1616/checkmark--v1.png"
                      alt="checkmark--v1"
                    />{" "}
                    Remote Property Management
                  </li>
                  <li>
                    <img
                      width="20"
                      height="20"
                      src="https://img.icons8.com/ios-glyphs/30/cc1616/checkmark--v1.png"
                      alt="checkmark--v1"
                    />{" "}
                    Power of Attorney Services
                  </li>
                  <li>
                    <img
                      width="20"
                      height="20"
                      src="https://img.icons8.com/ios-glyphs/30/cc1616/checkmark--v1.png"
                      alt="checkmark--v1"
                    />{" "}
                    Documentation Assistance and Loan Guidance
                  </li>
                </ul>
              </div>

              <div className="col-lg-2 col-md-6 footer-newsletter">
                <h4>Contact</h4>
                <strong>Phone:</strong>{" "}
                <a className="text-red-link" href="tel:+919416255871">
                  +91 9416255871
                </a>
                <br />
                <br />
                <strong>Phone:</strong>{" "}
                <a className="text-red-link" href="tel:+919996855871">
                  +91 9996855871
                </a>
                <br />
                <br />
                <strong>Mail:</strong>{" "}
                <a
                  className="text-red-link"
                  href="mailto:info.easyproperti@gmail.com"
                >
                  info.easyproperti@gmail.com
                </a>
                <br />
                <br />
              </div>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="copyright">
            &copy; Copyright{" "}
            <strong>
              <span>Easy Properti</span>
            </strong>
            . All Rights Reserved
          </div>
          <div className="credits">
            Designed by{" "}
            <a href="https://abhilaksharora.tech/">Abhilaksh Arora</a>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
