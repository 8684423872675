import React from "react";
import { Link } from "react-router-dom";
import { getToken, removeItem } from "../utils/LocalStorage";

const HeaderBackend = () => {
  const handleLogout = () => {
    removeItem("token");
    window.location.href = "/";
  };
  function mobileNavView() {
    const div = document.querySelector("#navbar");
    div.classList.remove("navbar");
    div.classList.toggle("navbar-mobile");
  }
  return (
    <div>
      <nav className="navbar navbar-expand-lg navbar-dark shadow-5-strong">
        <div className="container-fluid">
          <div className="container d-flex flex-row justify-content-between">
            <div className="d-flex flex-row justify-content-between align-items-center">
              <Link className="navbar-brand" to="/">
                {/* <h3>Easy Properti</h3> */}
                <img src="images/logo-light.png" className="header1-img" />
              </Link>
              <button onClick={mobileNavView} className="mobile-nav-toggle">
                <img
                  width="30"
                  height="30"
                  src="https://img.icons8.com/ios-glyphs/30/FFFFFF/menu--v1.png"
                  alt="menu--v1"
                />
              </button>
            </div>
            <div id="navbar" className="navbar flex">
              <div>
                <ul className="navbar-nav me-auto mb-2 mt-4 mb-lg-0">
                  <li className="nav-item">
                    <Link className="nav-link" to="/property-view">
                      <button className="btn btn-custom text-red mx-2">
                        View your properties
                      </button>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link">
                      <button
                        className="btn btn-custom text-red "
                        onClick={handleLogout}
                      >
                        Logout
                      </button>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default HeaderBackend;
