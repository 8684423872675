import React, { useEffect, useState } from "react";
import { Link, Navigate } from "react-router-dom";
import Header from "./Header";
import base from "../utils/base";

const Hero1 = ({ mainRef, aboutRef, serviceRef, contactRef }) => {


  const navigate = Navigate;

  const [propertyType1, setPropertyType1] = useState([]);
  useEffect(() => {
    fetch(`${base}/api/v1/list/types`)
      .then((response) => response.json())
      .then((data) => setPropertyType1(data))
      .catch((error) => console.error("Error fetching property types:", error));
  }, []);
  console.log(propertyType1);
  const [transactionType, setTransactionType] = useState("buy");
  const [propertyType, setPropertyType] = useState("");
  const [location, setLocation] = useState("");

  // console.log(propertyType1);
  console.log(location);
  console.log(propertyType);

  const handlePropertyTypeChange = (event) => {
    setPropertyType(event.target.value);
  };

  const handleLocationChange = (event) => {
    setLocation(event.target.value);
  };


  const handleSearch = async () => {
    localStorage.removeItem('Location')
    localStorage.removeItem('Property')
    localStorage.setItem('Location', location);
    localStorage.setItem('Property', propertyType);
    // return <Navigate to="/property-list" />; // Correctly use Navigate as JSX
    window.location.href = '/property-list'
  }


  return (
    <div id="hero" ref={mainRef}>
      <Header
        aboutRef={aboutRef}
        mainRef={mainRef}
        serviceRef={serviceRef}
        contactRef={contactRef}
      />
      <p className="adv">India's top rated Property Advisor's</p>
      <section className="">
        <div className="container position-relative hero-img">
          <div className="col-5"></div>

          <div className="hero-pattern">
            <img
              src="images/bg-pattern.png"
              alt=""
              width="1240"
              height="840"
              role="presentation"
              class="bg-pattern"
            />
            <img
              src="images/hero.png"
              alt=""
              width="816"
              height="659"
              role="presentation"
              class="hero-banner"
            />
          </div>
        </div>
        <div className="hero-button2">
          <div className="">
            <h1 className="">Find a property of your choice.</h1>
            <p className="text-light">
              If you're looking for a place where you can be yourself, don't
              give up. Keep searching until you find a place that feels like
              home.
            </p>
          </div>
        </div>
        <div className="hero-button rounded mt-3">
          <div className="hero-button3 bg-white px-4 py-3 rounded shadow">
            <div className="col-lg-4 col-md-12 col-sm-12 mb-2 mb-md-0">
              <div className="d-flex align-items-center">
                <HomeIcon className="text-link-red mr-1" />
                <span className="px-1">Property Type</span>
              </div>
              <div className="dropdown">
                {/* <button
                  className="btn dropdown-toggle w-100"
                  type="button"
                  id="dropdownMenuButton"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  {propertyType || "Select"}

                </button> */}
                <select value={propertyType} onChange={handlePropertyTypeChange}>
                  <option>Select</option>
                  {propertyType1.map((type, index) => (
                    <option key={index} value={type}>
                      {type}
                    </option>
                  ))}
                </select>


              </div>
            </div>
            <div className="col-lg-4 col-md-12 px-2 text-center">
              <div className="d-flex align-items-center mx-5">
                <LocateIcon className="text-link-red mr-1" />
                <span className="px-1">Location</span>
              </div>
              <input
                type="text"
                className="form-control"
                placeholder="Street, City, Zip..."
                value={location}
                onChange={handleLocationChange}
              />
            </div>
            <div className="col-lg-4 col-md-12 px-md-2 col-auto">
              <button className="btn text-red w-100" onClick={handleSearch}>Search</button>
            </div>
          </div>
        </div>
        <div className="hero-sell">
          <div className="">
            <h1 className="">Sell your property at best rates.</h1>
            <p className="text-light">
              A unique solution for Non-Indian Residents!
            </p>
          </div>
          <div className="hero-button1 rounded glow-button">
            <Link
              to="/sell"
              className="d-flex flex-row btn align-items-center justify-content-center text-center mx-2"
            >
              <BuildingIcon className="text-light mx-1" />
              <h4 className="pt-2 px-1 text-light">Sell</h4>
            </Link>
          </div>
        </div>
      </section>
    </div>
  );
};

function BuildingIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <rect width="16" height="20" x="4" y="2" rx="2" ry="2" />
      <path d="M9 22v-4h6v4" />
      <path d="M8 6h.01" />
      <path d="M16 6h.01" />
      <path d="M12 6h.01" />
      <path d="M12 10h.01" />
      <path d="M12 14h.01" />
      <path d="M16 10h.01" />
      <path d="M16 14h.01" />
      <path d="M8 10h.01" />
      <path d="M8 14h.01" />
    </svg>
  );
}

function HomeIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="m3 9 9-7 9 7v11a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2z" />
      <polyline points="9 22 9 12 15 12 15 22" />
    </svg>
  );
}

function LocateIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <line x1="2" x2="5" y1="12" y2="12" />
      <line x1="19" x2="22" y1="12" y2="12" />
      <line x1="12" x2="12" y1="2" y2="5" />
      <line x1="12" x2="12" y1="19" y2="22" />
      <circle cx="12" cy="12" r="7" />
    </svg>
  );
}
function SellIcon(props) {
  return (
    <svg
      {...props}
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      stroke="currentColor"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <circle cx="12" cy="10" r="3" />
      <circle cx="12" cy="16" r="3" />
      <line x1="10" y1="12" x2="14" y2="12" />
      <line x1="12" y1="14" x2="12" y2="10" />
    </svg>
  );
}

export default Hero1;