import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Header from "../components/Header";
import Header1 from "../components/Header1";
import Footer from "../components/Footer";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PulseLoader from "react-spinners/PulseLoader";
import base from "../utils/base";

const Buy = () => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const spinner = (
    <PulseLoader
      color="white"
      loading={isLoading}
      size={8}
      aria-label="Loading Spinner"
      data-testid="loader"
    />
  );
  // State to manage form inputs
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    mob: "",
    whatsapp: "",
    location: {
      state: "",
      district: "",
      city: "",
      tehsil: "",
      village: "",
      landmark: "",
      pincode: "",
    },
    budget: "",
    propertyType: "",
    timeToBuy: "",
    moreInfo: "",
  });

  // Handle form input change
  const handleChange = (e) => {
    const { name, value } = e.target;
    const updatedFormData = { ...formData };

    // Handle nested properties
    if (name.includes(".")) {
      const [parent, child] = name.split(".");
      updatedFormData[parent][child] = value;
    } else {
      updatedFormData[name] = value;
    }

    setFormData(updatedFormData);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    fetch(`${base}/api/v1/add-req`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
      body: JSON.stringify(formData),
    })
      .then((response) => {
        if (response.ok) {
          // Handle successful submission
          toast.success("Form Submitted");
          setIsSubmit(true);
        } else {
          // Handle errors
          console.error("Form submission failed");
        }
      })
      .catch((error) => {
        console.error("Error:", error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <div id="intro1">
      <div
        id="buy"
        className="bg-image shadow-2-strong buy-height"
        style={{ background: "rgba(6, 12, 34, 0.537)" }}
      >
        <Header1 />
        <ToastContainer
          position="top-center"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
          transition="Bounce"
        />
        <div className="sell mask d-flex rounded">
          <div className="col-lg-9 py-6 sell-pading" style={{ height: "100%" }}>
            <div className="row justify-content-center">
              <div className="col-xl-8 col-md-10 col-sm-12 bg-white">
                <div className="section-title">
                  <span>Property Requirements</span>
                  <h2>Property Requirements</h2>
                  <p>Your Gateway to Seamless Real Estate Transactions</p>
                </div>
                {!isSubmit ? (
                  <>
                    <form
                      className="bg-white rounded shadow-5-strong px-5"
                      onSubmit={handleSubmit}
                    >
                      <h5 className="text-bold">Personal Details</h5>
                      <hr />
                      <div className="d-flex flex-row">
                        <div className="mb-4 col-6">
                          <input
                            type="text"
                            name="name"
                            className="form-control"
                            placeholder="Name"
                            value={formData.name}
                            onChange={handleChange}
                            required
                          />
                        </div>
                        <div className="mb-4 col-6 mx-2">
                          <input
                            type="email"
                            name="email"
                            className="form-control"
                            placeholder="Email"
                            value={formData.email}
                            onChange={handleChange}
                            required
                          />
                        </div>
                      </div>
                      <div className="d-flex flex-row">
                        <div className="mb-4 col-6">
                          <input
                            type="number"
                            name="mob"
                            className="form-control"
                            placeholder="Mobile Number"
                            value={formData.mob}
                            onChange={handleChange}
                            required
                          />
                        </div>
                        <div className="mb-4 col-6 mx-2">
                          <input
                            type="number"
                            name="whatsapp"
                            className="form-control"
                            placeholder="Whatsapp Number"
                            value={formData.whatsapp}
                            onChange={handleChange}
                            required
                          />
                        </div>
                      </div>
                      <h5 className="mt-4 text-bold">Property Details</h5>
                      <hr />
                      <div className="d-flex flex-row">
                        <div className="mb-4 col-6">
                          <select
                            name="propertyType"
                            className="form-select"
                            value={formData.propertyType}
                            onChange={handleChange}
                            required
                          >
                            <option value="">Property Type</option>
                            <option value="House">House</option>
                            <option value="Apartment">Apartment/Flat</option>
                            <option value="Villa">Villa</option>
                            <option value="Land">Land</option>
                            <option value="Commercial">Commercial</option>
                          </select>
                        </div>
                        <div className="mb-4 col-6 mx-2">
                          <select
                            name="timeToBuy"
                            className="form-select"
                            value={formData.timeToBuy}
                            onChange={handleChange}
                            required
                          >
                            <option value="">Time to Buy</option>
                            <option value="immediately">Immediately</option>
                            <option value="within_1_month">
                              Within 1 Month
                            </option>
                            <option value="within_3_months">
                              Within 3 Months
                            </option>
                            <option value="within_6_months">
                              Within 6 Months
                            </option>
                            <option value="within_1_year">Within 1 Year</option>
                          </select>
                        </div>
                      </div>
                      <div className="d-flex flex-row">
                        <div className="mb-4 col-6">
                          <input
                            type="text"
                            name="location.state"
                            className="form-control"
                            placeholder="State"
                            value={formData.location.state}
                            onChange={handleChange}
                            required
                          />
                        </div>
                        <div className="mb-4 col-6 mx-2">
                          <input
                            type="text"
                            name="location.district"
                            className="form-control"
                            placeholder="District"
                            value={formData.location.district}
                            onChange={handleChange}
                            required
                          />
                        </div>
                      </div>
                      <div className="d-flex flex-row">
                        <div className="mb-4 col-6">
                          <input
                            type="text"
                            name="location.city"
                            className="form-control"
                            placeholder="City"
                            value={formData.location.city}
                            onChange={handleChange}
                            required
                          />
                        </div>
                        <div className="mb-4 col-6 mx-2">
                          <input
                            type="text"
                            name="location.tehsil"
                            className="form-control"
                            placeholder="Tehsil"
                            value={formData.location.tehsil}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="d-flex flex-row">
                        <div className="mb-4 col-6">
                          <input
                            type="text"
                            name="location.village"
                            className="form-control"
                            placeholder="Village"
                            value={formData.location.village}
                            onChange={handleChange}
                          />
                        </div>
                        <div className="mb-4 col-6 mx-2">
                          <input
                            type="text"
                            name="location.landmark"
                            className="form-control"
                            placeholder="Landmark"
                            value={formData.location.landmark}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="d-flex flex-row">
                        <div className="mb-4 col-6">
                          <input
                            type="text"
                            name="location.pincode"
                            className="form-control"
                            placeholder="Pincode"
                            value={formData.location.pincode}
                            onChange={handleChange}
                          />
                        </div>

                        <div className="mb-4 col-6 mx-2">
                          <input
                            type="number"
                            name="budget"
                            className="form-control"
                            placeholder="Budget"
                            value={formData.budget}
                            onChange={handleChange}
                            required
                          />
                        </div>
                      </div>

                      <div className="mb-4">
                        <textarea
                          type="text"
                          name="moreInfo"
                          className="form-control"
                          placeholder="Any other information"
                          value={formData.moreInfo}
                          onChange={handleChange}
                          required
                        />
                      </div>

                      <div className="row mb-4">
                        <button
                          type="submit"
                          className="btn btn-block text-blue"
                          data-mdb-ripple-init
                        >
                          {isLoading ? spinner : "Submit"}
                        </button>
                      </div>
                    </form>
                  </>
                ) : (
                  <>
                    <h3 className="text-center">
                      Your property requirements have been submitted. Our team
                      will connect with you in 24 to 48 hours. Thank you for
                      trusting{" "}
                      <a
                        href="https://easyproperti.com"
                        className="text-red-link"
                      >
                        Easy Properti
                      </a>
                      , Your Premier Real Estate Partner.
                    </h3>
                  </>
                )}
              </div>
            </div>
          </div>
          <div className="px-4 card-start">
            <div className="card-padding">
              <div className="card col-lg-3 my-6" style={{ width: "18rem" }}>
                <div className="card-body">
                  <h4 className="card-title bg-custom py-2 text-center">
                    Buying Facts
                  </h4>
                  <p className="card-text">
                    • Hire a professional inspector to thoroughly examine the
                    property for any issues or potential problems before making
                    an offer.
                    <br />
                    <br />• On average, homeowners' net worth is significantly
                    higher than that of renters.
                  </p>
                  <img
                    src="https://resumegenius.com/assets-builder/images/pelicano/contact-tips.svg"
                    alt="Illustration of person using laptop"
                    className="img-fluid"
                  />
                </div>
              </div>
            </div>
            <div className="py-4">
              <div className="card col-lg-3 my-6" style={{ width: "18rem" }}>
                <div className="card-body">
                  <h4 className="card-title bg-custom py-2 text-center">
                    Buying Facts
                  </h4>
                  <p className="card-text">
                    • Think about your long-term plans and whether the property
                    meets your future needs, such as space for a growing family
                    or accessibility as you age.
                    <br />
                    <br />• Read and understand all terms and conditions of the
                    purchase contract before signing. Consider seeking legal
                    advice if needed.
                  </p>
                  <img
                    src="https://resumegenius.com/assets-builder/images/pelicano/contact-tips.svg"
                    alt="Illustration of person using laptop"
                    className="img-fluid"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Buy;
