import React, { useState } from "react";
import { Link } from "react-router-dom";
import { storeToken } from "../utils/LocalStorage";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PulseLoader from "react-spinners/PulseLoader";
import HeaderLogin from "../components/HeaderLogin";
import Footer1 from "../components/Footer1";
import base from "../utils/base";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const spinner = (
    <PulseLoader
      color="white"
      loading={isLoading}
      size={8}
      aria-label="Loading Spinner"
      data-testid="loader"
    />
  );
  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    try {
      const response = await fetch(`${base}/api/v1/user/login`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
        body: JSON.stringify({ email, password }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message);
      }

      const data = await response.json();
      toast.success("Login Successful");
      setIsSubmit(true);
      storeToken(data.token, 432000);
      window.location.href = "/property-view";
    } catch (error) {
      console.error("Login failed:", error.message);
      toast.error(error.message);
      window.location.href = "/login";
    }
  };

  return (
    <div id="hero">
      <HeaderLogin text={"Register"} />
      <div className="container login position-relative hero-img">
        <ToastContainer
          position="top-center"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="light"
          transition="Bounce"
        />

        <div className="col-5"></div>
        <div className="register-pattern">
          <img
            src="images/bg-pattern.png"
            alt=""
            width="1240"
            height="840"
            role="presentation"
            class="bg-pattern"
          />
          <img
            src="images/hero.png"
            alt=""
            width="816"
            height="659"
            role="presentation"
            class="hero-banner"
          />
        </div>
      </div>
      <div className="login-form col-5 mt-5 pt-5">
        <div className="px-4">
          <div className="row justify-content-center">
            <div className="">
              <form
                className="bg-white rounded shadow-5-strong p-3 login-box"
                onSubmit={handleSubmit}
              >
                <h3 className="text-center mb-4">Login to Easy Properti</h3>
                <div className="form-content">
                  <div className="form-outline mb-4 px-1" data-mdb-input-init>
                    <input
                      type="email"
                      placeholder="Email"
                      className="form-control"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      required
                    />
                  </div>

                  <div className="form-outline mb-4 px-1" data-mdb-input-init>
                    <input
                      type="password"
                      className="form-control"
                      value={password}
                      placeholder="Password"
                      onChange={(e) => setPassword(e.target.value)}
                      required
                    />
                  </div>
                </div>
                <div className="d-flex flex-row justify-content-center px-4 mb-4">
                  <button
                    type="submit"
                    className="btn btn-custom1 btn-block text-red"
                    data-mdb-ripple-init
                  >
                    {isLoading ? spinner : "Login"}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <Footer1 />
    </div>
  );
};

export default Login;
