import React from "react";

const About = ({ aboutRef }) => {
  return (
    <div ref={aboutRef}>
      <div class="container-xxl py-5 my-5">
        <div class="container">
          <div class="row g-5">
            <div class="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
              <div class="border-start border-5 border-danger ps-4 mb-3">
                <h6 class="text-body text-uppercase mb-2">Why Choose Us!</h6>
                <h1 class="display-6 mb-0">About Us</h1>
              </div>
              <p class="mb-4 text-justify">
                Easy Properti: Your dedicated partners in simplifying property
                transactions, even remotely. With extensive experience and a
                commitment to excellence, we offer tailored solutions for
                buying, selling, and managing properties worldwide. From expert
                advice to seamless transactions, we prioritize transparency,
                integrity, and client satisfaction. Trust Easy Properti for
                successful real estate endeavors, wherever you are.
              </p>
              <div class="row gy-4 gx-4">
                <div class="col-sm-6 wow fadeIn" data-wow-delay="0.1s">
                  <div class="d-flex align-items-center mb-1">
                    <img
                      width="30"
                      height="30"
                      src="https://img.icons8.com/ios-glyphs/30/cc1616/checkmark--v1.png"
                      alt="checkmark--v1"
                    />
                    <h6 class="mb-0 px-1">
                      {" "}
                      Simplifying Property Valuation with our experts.
                    </h6>
                  </div>
                </div>
                <div class="col-sm-6 wow fadeIn" data-wow-delay="0.2s">
                  <div class="d-flex align-items-center mb-1">
                    <img
                      width="30"
                      height="30"
                      src="https://img.icons8.com/ios-glyphs/30/cc1616/checkmark--v1.png"
                      alt="checkmark--v1"
                    />
                    <h6 class="mb-0 px-1">
                      Buy, sell, or manage property effortlessly with our expert
                      guidance.
                    </h6>
                  </div>
                </div>
                <div class="col-sm-6 wow fadeIn" data-wow-delay="0.3s">
                  <div class="d-flex align-items-center mb-1">
                    <img
                      width="30"
                      height="30"
                      src="https://img.icons8.com/ios-glyphs/30/cc1616/checkmark--v1.png"
                      alt="checkmark--v1"
                    />
                    <h6 class="mb-0 px-1">
                      Hassle-free documentation assistance tailored to your
                      needs.
                    </h6>
                  </div>
                </div>
                <div class="col-sm-6 wow fadeIn" data-wow-delay="0.4s">
                  <div class="d-flex align-items-center mb-1">
                    <img
                      width="30"
                      height="30"
                      src="https://img.icons8.com/ios-glyphs/30/cc1616/checkmark--v1.png"
                      alt="checkmark--v1"
                    />{" "}
                    <h6 class="mb-0 px-1">
                      {" "}
                      Trust Easy Properti for transparency, integrity, and
                      client satisfaction in every transaction.
                    </h6>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-lg-6 wow fadeInUp" data-wow-delay="0.5s">
              <div
                class="position-relative overflow-hidden ps-5 pt-5 h-100"
                style={{ minHeight: "400px" }}
              >
                <img
                  class="position-absolute w-100 h-100"
                  src="https://cdn.britannica.com/05/157305-050-FD9CB47C.jpg"
                  alt=""
                  style={{ objectFit: "cover" }}
                />
                <div
                  class="position-absolute top-0 start-0 bg-white pe-3 pb-3"
                  style={{ width: "200px", height: "200px" }}
                >
                  <div class="d-flex flex-column justify-content-center text-center bg-box h-100 p-3">
                    <h1 class="text-white">10</h1>
                    <h2 class="text-white">Years</h2>
                    <h5 class="text-white mb-0">Experience</h5>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
