import React from "react";

const Why = () => {
  return (
    <div>
      <section id="why-us" className="why-us">
        <div className="container">
          <div className="row">
            <div className="col-lg-4" data-aos="fade-up">
              <div className="box">
                <span>01</span>
                <h4>Expertise</h4>
                <p>
                  Industry-seasoned professionals delivering exceptional results
                  with in-depth market knowledge.
                </p>
              </div>
            </div>

            <div
              className="col-lg-4 mt-4 mt-lg-0"
              data-aos="fade-up"
              data-aos-delay="150"
            >
              <div className="box bg-box1">
                <span>02</span>
                <h4>Transparency</h4>
                <p>
                  Open communication and comprehensive information empower
                  informed decisions.
                </p>
              </div>
            </div>

            <div
              className="col-lg-4 mt-4 mt-lg-0"
              data-aos="fade-up"
              data-aos-delay="300"
            >
              <div className="box">
                <span>03</span>
                <h4>Convenience</h4>
                <p>
                  Hassle-free services designed to save you time and focus on
                  your priorities.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Why;
