import React from "react";
import { Link } from "react-router-dom";
import { removeItem } from "../utils/LocalStorage";

const HeaderBackend2 = () => {
  const handleLogout = () => {
    removeItem("token");
    window.location.href = "/";
  };
  function mobileNavView() {
    const div = document.querySelector("#navbar");
    div.classList.remove("navbar");
    div.classList.toggle("navbar-mobile");
  }
  return (
    <div>
      <nav className="navbar navbar-expand-lg navbar-dark shadow-5-strong">
        <div className="container-fluid">
          <div className="container d-flex flex-row justify-content-between">
            <div className="d-flex flex-row justify-content-between align-items-center">
              <Link className="navbar-brand" to="/">
                {/* <h3>Easy Properti</h3> */}
                <img src="images/2.png" className="header-img" />
              </Link>
              <button onClick={mobileNavView} className="mobile-nav-toggle">
                <img
                  width="30"
                  height="30"
                  src="https://img.icons8.com/ios-glyphs/30/000000/menu--v1.png"
                  alt="menu--v1"
                />
              </button>
            </div>
            <div id="navbar" className="navbar flex">
              <div>
                <ul className="navbar-nav me-auto mb-2 mt-4 mb-lg-0">
                  <li className="nav-item">
                    <Link className="nav-link" to="/property-upload">
                      <button className="btn btn-custom1 text-red mx-2">
                        Upload Property
                      </button>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link">
                      <button
                        className="btn btn-custom text-red "
                        onClick={handleLogout}
                      >
                        Logout
                      </button>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <button
            className="navbar-toggler"
            type="button"
            data-mdb-toggle="collapse"
            data-mdb-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <i className="fas fa-bars"></i>
          </button>
        </div>
      </nav>
    </div>
  );
};

export default HeaderBackend2;
