import React from "react";

const Abroad = () => {
  return (
    <div>
      <div className="container-xxl py-5 mb-4">
        <div className="container">
          <div className="row g-5">
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.1s">
              <div
                className="position-relative overflow-hidden ps-5 pt-5 h-100"
                style={{ minHeight: "400px" }}
              >
                <img
                  className="position-absolute w-100 h-100"
                  src="images/bg-login.jpg"
                  alt=""
                  style={{ objectFit: "cover" }}
                />
                <div
                  className="position-absolute top-0 start-0 bg-white pe-3 pb-3"
                  style={{ width: "200px", height: "200px" }}
                >
                  <div className="d-flex flex-column justify-content-center text-center bg-box h-100 p-3">
                    <h1 className="text-white">10</h1>
                    <h2 className="text-white">Years</h2>
                    <h5 className="text-white mb-0">Experience</h5>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 wow fadeInUp" data-wow-delay="0.5s">
              <div className="h-100">
                <div className="border-start border-5 border-danger ps-4 mb-3">
                  <h6 className="text-uppercase mb-2 glow-button">Expertise!</h6>
                  <h1 className="display-6 mb-0">
                    Unique Solutions For Non Indian Residentials!
                  </h1>
                </div>
                <p className="text-justify">
                  Easy Properti is your gateway to hassle-free
                  property management and transactions for those living abroad.
                  Our dedicated team understands the unique challenges faced by
                  international property owners and offers comprehensive
                  services to ensure your investments are well-maintained and
                  profitable.
                </p>
                <p className="mb-4 text-justify">
                  From remote property management to Power of Attorney services
                  for seamless transactions, we handle every aspect with
                  expertise and efficiency. Trust Smooth Abroad Properties to
                  take the stress out of managing your properties from afar,
                  allowing you to enjoy peace of mind no matter where you are in
                  the world.
                </p>
                <div className="border-top mt-4 pt-4">
                  <div className="row g-4">
                    <div
                      className="col-sm-4 d-flex wow fadeIn"
                      data-wow-delay="0.1s"
                    >
                      <img
                        width="20"
                        height="20"
                        src="https://img.icons8.com/ios-glyphs/30/cc1616/checkmark--v1.png"
                        alt="checkmark--v1"
                      />{" "}
                      <h6 className="mb-0">{" "}Ontime at services</h6>
                    </div>
                    <div
                      className="col-sm-4 d-flex wow fadeIn"
                      data-wow-delay="0.3s"
                    >
                      <img
                        width="20"
                        height="20"
                        src="https://img.icons8.com/ios-glyphs/30/cc1616/checkmark--v1.png"
                        alt="checkmark--v1"
                      />{" "}
                      <h6 className="mb-0">{" "}24/7 hours services</h6>
                    </div>
                    <div
                      className="col-sm-4 d-flex wow fadeIn"
                      data-wow-delay="0.5s"
                    >
                      <img
                        width="20"
                        height="20"
                        src="https://img.icons8.com/ios-glyphs/30/cc1616/checkmark--v1.png"
                        alt="checkmark--v1"
                      />{" "}
                      <h6 className="mb-0">{" "}Verified professionals</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Abroad;
