import React from "react";
import Header1 from "./Header1";

const PropertyCardDetail = () => {
  return (
    <div>
      <Header1 />
      <div className="estate pt-4 mt-4">
        <div className="container bootstrap snippets bootdey">
          <div className="row">
            <div className="col-md-12">
              <div className="row">
                <div className="col-md-7">
                  <div className="product-gallery">
                    <div className="primary-image">
                      <a
                        href="#"
                        className="theater"
                        rel="group"
                        hidefocus="true"
                      >
                        <img
                          src="https://www.bootdey.com/image/700x300/FF69B4/000000"
                          className="img-responsive"
                          alt=""
                        />
                      </a>
                    </div>
                    <div className="thumbnail-images">
                      <a
                        href="#"
                        className="theater"
                        rel="group"
                        hidefocus="true"
                      >
                        <img
                          src="https://www.bootdey.com/image/500x300/20B2AA/000000"
                          alt=""
                        />
                      </a>
                      <a
                        href="#"
                        className="theater"
                        rel="group"
                        hidefocus="true"
                      >
                        <img
                          src="https://www.bootdey.com/image/500x300/FFA07A/000000"
                          alt=""
                        />
                      </a>
                      <a
                        href="#"
                        className="theater"
                        rel="group"
                        hidefocus="true"
                      >
                        <img
                          src="https://www.bootdey.com/image/500x300/7B68EE/000000"
                          alt=""
                        />
                      </a>
                      <a
                        href="#"
                        className="theater"
                        rel="group"
                        hidefocus="true"
                      >
                        <img
                          src="https://www.bootdey.com/image/500x300/C71585/000000"
                          alt=""
                        />
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-md-5">
                  <div className="product-info">
                    <h3>3015 Grand Avenue, CocoWalk</h3>
                    <div className="wp-block property list no-border">
                      <div className="wp-block-content clearfix">
                        <small>10 days only</small>
                        <h4 className="content-title">Special Offer</h4>
                        <p className="description mb-15">
                          Lorem ipsum dolor sit amet, consectetur adipisicing
                          elit. Ut tincidunt purus iaculis ipsum dictum non
                          dictum quam. Donec id elit non mi porta gravida at
                          eget metus. Lorem ipsum dolor sit amet, consectetur
                          adipisicing elit. Ut.
                        </p>
                        <span className="pull-left">
                          <span className="price">$230</span>
                          <span className="period">per month</span>
                        </span>
                        <span className="pull-right">
                          <span className="capacity">
                            <i className="fa fa-user"></i>
                            <i className="fa fa-user"></i>
                          </span>
                        </span>
                      </div>
                      <div className="wp-block-footer style2 mt-15">
                        <ul className="aux-info">
                          <li>
                            <i className="fa fa-building"></i>2300 Sq Feet
                          </li>
                          <li>
                            <i className="fa fa-user"></i> 5 Bedrooms
                          </li>
                          <li>
                            <i className="fa fa-tint"></i> 2 Bathrooms
                          </li>
                          <li>
                            <i className="fa fa-car"></i> 3 Garages
                          </li>
                        </ul>
                      </div>
                    </div>
                    <span className="clearfix"></span>
                    <p>
                      Lorem ipsum dolor sit amet, consectetur adipisicing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua.
                    </p>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-md-12">
                  <div className="tabs-framed boxed">
                    <ul className="nav nav-tabs clearfix">
                      <li className="nav-item">
                        <a
                          className="nav-link active"
                          data-toggle="tab"
                          href="#tab-1"
                        >
                          Additional details
                        </a>
                      </li>
                      <li className="nav-item">
                        <a
                          className="nav-link"
                          data-toggle="tab"
                          href="#tab-map"
                        >
                          Map
                        </a>
                      </li>
                    </ul>

                    <div className="tab-content">
                      <div className="tab-pane fade show active" id="tab-1">
                        <div className="tab-body">
                          <div className="section-title-wr">
                            <h3 className="section-title left">
                              Property description
                            </h3>
                          </div>
                          <p>
                            Lorem ipsum dolor sit amet, consectetur adipiscing
                            elit, sed do eiusmod tempor incididunt ut labore et
                            dolore magna aliqua. Ut enim ad minim veniam, quis
                            nostrud exercitation ullamco laboris nisi ut aliquip
                            ex ea commodo consequat. Duis aute irure dolor in
                            reprehenderit in voluptate velit esse cillum dolore
                            eu fugiat nulla pariatur. Excepteur sint occaecat
                            cupidatat non proident, sunt in culpa qui officia
                            deserunt mollit anim id est laborum.
                          </p>

                          <div className="section-title-wr">
                            <h3 className="section-title left">
                              Additional details
                            </h3>
                          </div>
                          <table className="table table-bordered table-striped table-hover table-responsive">
                            <tbody>
                              <tr>
                                <td>
                                  <strong>Property Size:</strong> 2300 Sq Ft
                                </td>
                                <td>
                                  <strong>Lot size:</strong> 5000 Sq Ft
                                </td>
                                <td>
                                  <strong>Price:</strong> $23000
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <strong>Rooms:</strong> 5
                                </td>
                                <td>
                                  <strong>Bedrooms:</strong> 4
                                </td>
                                <td>
                                  <strong>Bathrooms:</strong> 2
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <strong>Garages:</strong> 2
                                </td>
                                <td>
                                  <strong>Roofing:</strong> New
                                </td>
                                <td>
                                  <strong>Structure Type:</strong> Bricks
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <strong>Year built:</strong> 1991
                                </td>
                                <td>
                                  <strong>Available from:</strong> 1 August 2014
                                </td>
                                <td></td>
                              </tr>
                            </tbody>
                          </table>

                          <div className="section-title-wr">
                            <h3 className="section-title left">
                              Location details
                            </h3>
                          </div>
                          <table className="table table-bordered table-striped table-hover table-responsive">
                            <tbody>
                              <tr>
                                <td>
                                  <strong>
                                    <strong>Property Size:</strong> 2300 Sq Ft
                                  </strong>
                                </td>
                                <td>
                                  <strong>Lot size:</strong> 5000 Sq Ft
                                </td>
                                <td>
                                  <strong>Price:</strong> $23000
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <strong>Rooms:</strong> 5
                                </td>
                                <td>
                                  <strong>Bedrooms:</strong> 4
                                </td>
                                <td>
                                  <strong>Bathrooms:</strong> 2
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <strong>Garages:</strong> 2
                                </td>
                                <td>
                                  <strong>Roofing:</strong> New
                                </td>
                                <td>
                                  <strong>Structure Type:</strong> Bricks
                                </td>
                              </tr>
                              <tr>
                                <td>
                                  <strong>Year built:</strong> 1991
                                </td>
                                <td>
                                  <strong>Available from:</strong> 1 August 2014
                                </td>
                                <td></td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div className="tab-pane fade" id="tab-map">
                        <div className="tab-body">
                          <div
                            id="mapCanvas"
                            className="map-canvas no-margin"
                            style={{
                              position: "relative",
                              overflow: "hidden",
                              transform: "translateZ(0px)",
                              backgroundColor: "rgb(229, 227, 223)",
                            }}
                          >
                            {/* Google Maps or other map component can be added here */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PropertyCardDetail;
