import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import base from "../utils/base";

const Property = () => {
  const [properties, setProperties] = useState([]);

  useEffect(() => {
    // Function to fetch properties from the backend
    const fetchProperties = async () => {
      try {
        // Adjust this URL to where your backend API is hosted, and the correct endpoint
        const response = await fetch(
          `${base}/api/v1/list/listing`
        );
        const data = await response.json();
        if (response.ok) {
          const limitedProperties = data.property.slice(0, 6);
          setProperties(limitedProperties);
        } else {
          throw new Error("Failed to fetch properties");
        }
      } catch (error) {
        console.error("Error fetching properties:", error.message);
      }
    };

    fetchProperties();
  }, []);
  console.log(properties);
  return (
    <div className="my-4 py-4">
      <div className="section-title">
        <span>Popular Properties</span>
        <h2>Popular Properties</h2>
        <p>Your Gateway to Seamless Real Estate Transactions</p>
      </div>
      <div className="estate">
        <div className="container bootstrap snippets bootdey">
          <div className="estate-main">
            {properties.map((property, index) => {
              return (
                <>
                  <div className="col-md-3 mx-4 icon-box">
                    <div className="">
                      <div className="product-gallery">
                        <div className="primary-image overflow-hidden">
                          <Carousel autoPlay showThumbs={false}>
                            {property.images.map((img, index) => {
                              return (
                                <>
                                  <div key={index}>
                                    <img
                                      src={img}
                                      className="img-responsive carousel-image1"
                                      alt=""
                                    />
                                  </div>
                                </>
                              );
                            })}
                          </Carousel>
                        </div>
                      </div>
                    </div>
                    <div className="">
                      <div className="product-info">
                        <h3 className="">{property.title}</h3>
                        <h4 className="">
                          {property.sector}
                          {", "}
                          {property.city}
                          {", "}
                          {property.state}
                        </h4>
                        <div className="wp-block property list no-border">
                          <div className="wp-block-content clearfix">
                            <span className="pull-left">
                              <span className="price">
                                Rs. {property.price}
                              </span>{" "}
                            </span>
                          </div>
                          <div className="wp-block-footer style2 mt-15 rounded">
                            <ul className="aux-info">
                              <li>
                                <i className="fa fa-building"></i>
                                {property.area} Sq Feet
                              </li>
                              <li>
                                <i className="fa fa-user"></i>{" "}
                                {property.bedrooms} Bedrooms
                              </li>
                              <li>
                                <i className="fa fa-tint"></i>{" "}
                                {property.bathrooms} Bathrooms
                              </li>
                              <li>
                                <i className="fa fa-car"></i> {property.parking}{" "}
                                Parking
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              );
            })}
          </div>
        </div>
        <div className="text-center my-4 pt-2">
          <Link to="/property-list">
            <h5 className="glow-button">View all properties</h5>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Property;
