import React from "react";

const Clients = () => {
  return (
    <div>
      <section id="clients" className="clients mb-2 py-4">
        <div className="container" data-aos="zoom-in">
          <div className="row d-flex clients-loc align-items-center text-white text-center justify-content-center">
            <div className="col-lg-2 col-md-4 col-6 d-flex flex-row">
              <img
                width="30"
                height="30"
                src="https://img.icons8.com/ios-filled/50/FFFFFF/marker.png"
                alt="marker"
              />
              <h3>Chandiagrh</h3>
            </div>

            <div className="col-lg-2 col-md-4 col-6 d-flex flex-row">
              <img
                width="30"
                height="30"
                src="https://img.icons8.com/ios-filled/50/FFFFFF/marker.png"
                alt="marker"
              />
              <h3>Rajasthan</h3>
            </div>

            <div className="col-lg-2 col-md-4 col-6 d-flex flex-row">
              <img
                width="30"
                height="30"
                src="https://img.icons8.com/ios-filled/50/FFFFFF/marker.png"
                alt="marker"
              />
              <h3>Harayana</h3>
            </div>
            <div className="col-lg-2 col-md-4 col-6 d-flex flex-row">
              <img
                width="30"
                height="30"
                src="https://img.icons8.com/ios-filled/50/FFFFFF/marker.png"
                alt="marker"
              />
              <h3>Punjab</h3>
            </div>
            <div className="col-lg-2 col-md-4 col-6 d-flex flex-row">
              <img
                width="30"
                height="30"
                src="https://img.icons8.com/ios-filled/50/FFFFFF/marker.png"
                alt="marker"
              />
              <h3>Delhi</h3>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Clients;
