import React from "react";

const Services = ({ serviceRef }) => {
  return (
    <section ref={serviceRef} id="services" className="services">
      <div className="container">
        <div className="section-title">
          <span>Services</span>
          <h2>Services</h2>
          <p>Your Gateway to Seamless Real Estate Transactions</p>
        </div>

        <div className="row justify-content-center d-flex flex-row">
          <div
            className="col-lg-5 col-md-6 d-flex align-items-stretch"
            data-aos="fade-up"
          >
            <div className="icon-box">
              <div className="icon">
                <img
                  width="64"
                  height="64"
                  src="https://img.icons8.com/external-outline-berkahicon/64/FFFFFF/external-house-real-estate-property-outline-berkahicon-14.png"
                  alt="external-house-real-estate-property-outline-berkahicon-14"
                />
              </div>
              <h4>Buying and Selling</h4>
              <p>
                Seamlessly connect buyers and sellers for transparent
                transactions, guided by experienced agents.
              </p>
            </div>
          </div>

          <div
            className="col-lg-5 col-md-6 d-flex align-items-stretch mt-4 mt-md-0"
            data-aos="fade-up"
            data-aos-delay="150"
          >
            <div className="icon-box">
              <div className="icon">
                <img
                  width="50"
                  height="50"
                  src="https://img.icons8.com/ios/50/FFFFFF/remote-control.png"
                  alt="remote-control"
                />
              </div>
              <h4>Remote Property Management</h4>
              <p>
                Trust us to manage your property in India while you're abroad,
                handling maintenance, tenant management, and legal matters.
              </p>
            </div>
          </div>

          <div
            className="col-lg-5 col-md-6 d-flex align-items-stretch mt-4"
            data-aos="fade-up"
            data-aos-delay="750"
          >
            <div className="icon-box">
              <div className="icon">
                <img
                  width="50"
                  height="50"
                  src="https://img.icons8.com/ios/50/FFFFFF/services--v1.png"
                  alt="services--v1"
                />
              </div>
              <h4>Power of Attorney Services</h4>
              <p>
                Authorize us to act on your behalf for property transactions in
                India, ensuring a smooth process with funds transferred directly
                to your account.
              </p>
            </div>
          </div>
          <div
            className="col-lg-5 col-md-6 d-flex align-items-stretch mt-4"
            data-aos="fade-up"
            data-aos-delay="450"
          >
            <div className="icon-box">
              <div className="icon">
                <img
                  width="50"
                  height="50"
                  src="https://img.icons8.com/external-others-pike-picture/50/FFFFFF/external-Documentation-productivity-others-pike-picture-3.png"
                  alt="external-Documentation-productivity-others-pike-picture-3"
                />
              </div>
              <h4>Documentation Assistance and Loan Guidance</h4>
              <p>
                Simplify paperwork and secure property loans with our expert
                guidance, covering registry documentation and loan application
                procedures.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Services;
