import React from "react";
import { Link } from "react-router-dom";

const HeaderList = () => {
  function mobileNavView() {
    const div = document.querySelector("#navbar");
    div.classList.remove("navbar");
    div.classList.toggle("navbar-mobile");
  }
  return (
    <div>
      <nav className="navbar navbar-expand-lg navbar-dark shadow-5-strong">
        <div className="container-fluid">
          <div className="container d-flex flex-row justify-content-between">
            <div className="d-flex flex-row justify-content-between align-items-center">
              <Link className="navbar-brand" to="/">
                {/* <h3>Easy Properti</h3> */}
                <img src="images/2.png" className="header-img" />
              </Link>
              <button onClick={mobileNavView} className="mobile-nav-toggle">
                <img
                  width="30"
                  height="30"
                  src="https://img.icons8.com/ios-glyphs/30/000000/menu--v1.png"
                  alt="menu--v1"
                />
              </button>
            </div>
            <div id="navbar" className="navbar flex">
              <div>
                <ul className="navbar-nav me-auto mt-4 mb-2 mb-lg-0">
                <li className="nav-item">
                    <Link className="nav-link active" to="/buy">
                      <button className="btn-custom text-red mx-2">Buy</button>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link active" to="/sell">
                      <button className="btn-custom text-red mx-2">Sell</button>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link active" to="/login">
                      <button className="btn-custom text-red mx-2">
                        Dealer Login
                      </button>
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link active" to="/register">
                      <button className="btn-custom text-red mx-2">
                        Dealer Register
                      </button>
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <button
            className="navbar-toggler"
            type="button"
            data-mdb-toggle="collapse"
            data-mdb-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <i className="fas fa-bars"></i>
          </button>
        </div>
      </nav>
    </div>
  );
};

export default HeaderList;
